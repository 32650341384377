import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ScoreBadge from "../../../../components/ScoreBadge";
import {CustomButton, HalfCircleChart} from "shared-components"
import Stepper from "../../../../components/Stepper"
import iconClock from "shared-assets/icons/clock.svg"
import iconCalendarX from "shared-assets/icons/calendar-x.svg"
import {monthDate} from "../../../../helpers/dates";
import ReadMore from "../../../../components/ReadMore";
import {DevelopmentAreaInterface, FeedbackProgressInterface} from "../../../../interfaces/common";
import {calculateScoreRespondents, calculateScoreSelf} from "../../../../helpers/feedback";
import {isProgressDone, isDeadlinePassed} from "../../../../helpers/questionnaire";
import i18n from "../../../../lang/i18n";

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();

interface Props {
  developmentArea: DevelopmentAreaInterface
  type: string
  questionnaireId?: string
  conclusion: string
}

const QuestionnaireState: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const navigate = useNavigate()
  const progress: FeedbackProgressInterface | null = props.developmentArea.feedback_progresses.length > 0 ?
    props.developmentArea.feedback_progresses[props.developmentArea.feedback_progresses.length - 1] : null

  const deadLinePassed = isDeadlinePassed(progress)

  const discussAnalysisResult = () => {
    let message = t('discuss-analysis-result') + ' ' + props.conclusion
    navigate(`/?message=${encodeURIComponent(message)}`)
  }

  const createStartAnalysisUrl = () => {
    return `/feedback/create?id=${props.developmentArea.id}&type=focus_area&title=${props.developmentArea.title}&content=${encodeURIComponent(props.developmentArea.description)}`;
  }

  if (progress !== null && isProgressDone(progress)) {
    const scoreSelf = calculateScoreSelf(progress.self_assessment_answer)
    const scoreRespondents = calculateScoreRespondents(progress.feedback_results)
    return (
      <div className="mt-1 p-4 rounded-md bg-neutral-800">
        <div className="flex justify-end text-neutral-400 text-xs">
            {t("last-updated")}:&nbsp;{monthDate(new Date(progress.last_feedback), lang)}
        </div>

        <div className="mt-4 flex justify-center">
          <div className="w-64">
            <HalfCircleChart value1={scoreSelf}
                             value2={scoreRespondents} maxValue={5} circleNumbers={true}/>
          </div>

        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="text-neutral-400">{t("self-assessment")}:</div>
          <ScoreBadge score={scoreSelf} type={props.type} self={true} size="sm"/>
        </div>

        <hr className="border-neutral-600 mt-2"/>

        <div className="flex justify-between items-center mt-2">
          <div className="text-neutral-400">{t("feedback-score")}:</div>
          <ScoreBadge score={scoreRespondents} type={props.type} self={false} size="sm"/>
        </div>

        <hr className="border-neutral-600 mt-2"/>

        <div className="flex justify-between items-center mt-2">
          <div className="text-neutral-400">{t("perception-gap")}:</div>
          <ScoreBadge score={Math.abs(scoreSelf - scoreRespondents)} type="none"
                      self={true} size="sm"/>
        </div>

        <hr className="border-neutral-600 mt-2 mb-4"/>

        <ReadMore linkAbove={false} initialHeight="0">
          <div className="mt-4 font-medium">{t("analysis-summary")}</div>
          <div className="text-neutral-400">
            {props.conclusion}
          </div>

          {
            (props.conclusion.length > 0 && !props.conclusion.startsWith("Failed")) &&
              <CustomButton className="mt-4" buttonType="outlineWhiteBase"
                            leftIcon={<img src={emmaAvatar} alt="icon" className="w-4 h-4 mr-1.5" />}
                            onClick={() => discussAnalysisResult()}>
                {t("discuss-analysis-results")}
              </CustomButton>
          }

          <CustomButton className="mt-2" buttonType="outlineWhiteBase"
                        onClick={() => {
                          navigate('/feedback/result/focus_area/' + props.developmentArea.id);
                        }}>
            {t("see-all-insights")}
          </CustomButton>
        </ReadMore>

      </div>
    )
  } else if (progress !== null) {
    const completedSteps: Array<number> = [0]
    let step = 2
    if (progress.has_self_assessment) {
      completedSteps.push(1)
      step = 3
    }
    let step2Txt: string
    let step3Txt: string
    if (progress.has_self_assessment) {
      step2Txt = lang === 'sv' ? "Klar" : "Completed"
      step3Txt = t("waiting-for-feedback")
    } else {
      if (deadLinePassed) {
        step2Txt = t("waiting-for-feedback-info")
        step3Txt = t("waiting-for-feedback-and-self")
      } else {
        step2Txt = t("waiting-for-feedback-info")
        step3Txt = t("waiting-for-feedback-and-self")
      }

    }

    const stepsData = [
      {
        title: t("feedback-request"),
        description: t("sent-on") + " " + monthDate(new Date(progress.created), lang),
        content: null,
      },
      {
        title: t("self-assessment"),
        description: step2Txt,
        content: null,
      },
      {
        title: t("current-state-analysis"),
        description: step3Txt,
        content: null,
      },
    ]
    return (
      <div className="p-4 rounded-md bg-neutral-800">
        {
          step === 2 &&
          <div className="text-neutral-400">
            {t("to-analyse-steps")}
          </div>
        }

        <Stepper
          steps={stepsData}
          direction='vertical'
          currentIndex={step - 1}
          completedSteps={completedSteps}
        />

        {
          (step === 3 && !deadLinePassed) &&
            <div className="mt-6 border border-neutral-500 rounded-md">
                <div className="flex items-center p-4">
                    <img alt="clock" src={iconClock} className="h-6"/>
                    <div className="ml-4 text-sm font-medium text-neutral-200">
                      {
                        lang === 'sv' ?
                          'Vi mejlar dig när analysen är redo, senast'
                          :
                          'We’ll email you when the analysis is ready, no later than'
                      }
                        &nbsp;{monthDate(new Date(progress.deadline), lang)}.
                    </div>
                </div>
            </div>
        }

        {
          deadLinePassed &&
            <div className="mt-4 border border-neutral-500 rounded-md p-4 bg-neutral-700 flex items-center">
                <img alt="clock" src={iconCalendarX} className="h-6"/>
                <div className="ml-2 text-sm font-medium text-neutral-200">
                  {
                    lang === 'sv' ?
                      "Du fick inte in tillräckligt många svar den här gången. Ingen fara – be om feedback igen och överväg att själv informera mottagarna, justera frågorna eller uppdatera mottagarlistan." :
                      "The deadline has passed, and you didn’t get enough answers. Don’t worry–start a new one and consider informing the recipients yourself, adjusting the questions, or updating the recipient list."
                  }
                </div>
            </div>
        }

        {
          deadLinePassed &&
            <CustomButton className="mt-4" onClick={() => navigate(createStartAnalysisUrl())}>
              {t("start-analysis")}
            </CustomButton>

        }

        {
          (!deadLinePassed && step === 2) &&
            <CustomButton className="mt-4"
                          onClick={() => navigate('/feedback/questionnaire/' + progress.questionnaire_id)}>
              {t("continue")}
            </CustomButton>
        }
      </div>
    )
  } else {
    return (
      <div className="mt-1 p-4 rounded-md bg-neutral-800">
        <div className="text-neutral-400">
          {t("start-analysis-intro")}
        </div>
        <CustomButton className="mt-4" onClick={() => navigate(createStartAnalysisUrl())}>{t("start-analysis")}</CustomButton>
      </div>
    )
  }
}

export default QuestionnaireState;