import React from 'react';
import {useTranslation} from 'react-i18next';
import {HalfCircleChart} from "shared-components";
import ScoreBadge from "../ScoreBadge";
import {FeedbackQuestionInterface} from "../../interfaces/common";

interface Props {
  question: FeedbackQuestionInterface
  type: string
  size?: string
  circleNumbers?: boolean
}

const ScoreTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const scoreSelf = props.question.selfScore
  const scoreRespondents = props.question.average


  const textCls = props.size && props.size === "small" ?
    "text-xs text-neutral-400" : "text-neutral-400"
  return (
    <div className="flex items-center">
      <div className="w-32 h-32">
        <HalfCircleChart value1={scoreSelf}
                         value2={scoreRespondents}
                         circleNumbers={props.circleNumbers}
                         maxValue={5} type={props.type}/>
      </div>
      <div className="ml-4 w-full">
        <div className="flex justify-between">
          <div className={textCls}>{t("self-assessment")}:</div>
          <ScoreBadge score={scoreSelf} type={props.type} size={props.size} self={true}/>
        </div>

        <hr className="border-neutral-600 my-2"/>

        <div className="flex justify-between">
          <div className={textCls}>{t("feedback-score")}:</div>
          <ScoreBadge score={scoreRespondents} type={props.type} size={props.size} self={false}/>
        </div>

        <hr className="border-neutral-600 my-2"/>

        <div className="flex justify-between">
          <div className={textCls}>{t("perception-gap")}:</div>
          <ScoreBadge score={Math.abs(props.question.gap)}
                      size={props.size} type="none"/>
        </div>

        <hr className="border-neutral-600 mt-2"/>
      </div>
    </div>
  )
}

export default ScoreTable;