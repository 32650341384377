import React from 'react';
import '../../../../lang/i18n';
import {useTranslation} from 'react-i18next';
import { CustomCard as Card, CardHeader, CardBody, CardFooter } from 'shared-components'

import {DevelopmentAreaInterface, FeedbackQuestionInterface} from "../../../../interfaces/common";
import { CustomButton } from "shared-components"
import ScoreTable from "../../../../components/ScoreTable";
import {isAreaDone} from "../../../../helpers/questionnaire";
import {calculateScoreSelf, calculateScoreRespondents} from "../../../../helpers/feedback";


interface DevelopmentCardProps {
  developmentArea: DevelopmentAreaInterface;
  onClick: () => void;
  leftIcon?: string;
}

const DevelopmentCard = ({
                           developmentArea,
                           onClick,
                           leftIcon,
                         }: DevelopmentCardProps) => {
  const { t } = useTranslation();
  const isDone = isAreaDone(developmentArea);

  const createQuestion = () => {
    // This should be changed so that we get pre-caculated values from endpoint. Hack for now :-/
    const progress = developmentArea.feedback_progresses[developmentArea.feedback_progresses.length - 1]
    const average = calculateScoreRespondents(progress.feedback_results)
    const selfScore = calculateScoreSelf(progress.self_assessment_answer)
    const gap = selfScore - average
    // console.log(selfScore + ' - ' + average + ' = ' + gap)
    const question: FeedbackQuestionInterface = {
      average: average,
      selfScore: selfScore,
      gap: gap,
      responses: [],
      question: ""
    }
    return question
  }

  return (
    <Card>
      <CardHeader className="border-neutral-600 border-b flex items-center px-4 py-3 bg-neutral-700 rounded-t-md">
        {leftIcon && (
          <img src={leftIcon} alt="icon" className="w-6 h-6 mr-1.5" />
        )}
        <h3 className="text-base font-medium text-gray-800 dark:text-white flex-grow">
          {developmentArea.title}
        </h3>
      </CardHeader>
      {
        (isDone) &&
          <CardBody className="p-4">
            <ScoreTable question={createQuestion()}
                        type="focus_area" size="small" />
          </CardBody>
      }

      <CardFooter>
        {
          (developmentArea.feedback_progresses.length > 0) ? (
            <CustomButton onClick={onClick}>{t('continue')}</CustomButton>
          ) : (
            <CustomButton onClick={onClick} buttonType="outlineWhiteBase">{t('get-started')}</CustomButton>
          )
        }
      </CardFooter>
    </Card>
  );
};

export default DevelopmentCard;