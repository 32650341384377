import {CONFIG} from "../constants";

export interface ApiCallResponse {
  response: Response,
  json?: any,
  isExpectedError: boolean,
  errorName?: string,
  errorMessage?: string,
}

export default class Api {
  static myInstance: Api;
  private readonly baseUrl: string;
  public apiToken: string;

  constructor() {
    this.apiToken = window.localStorage[CONFIG.TOKEN_NAME]
    this.baseUrl = CONFIG.API_ENDPOINT;
  }

  static getInstance(): Api {
    if (Api.myInstance == null) {
      Api.myInstance = new Api();
    }
    return this.myInstance;
  }

  public get(url: string, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.getRaw(url);
    return this.makeCall(call, expectedErrors)
  }

  public post(url: string, data: any, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.postRaw(url, data)
    return this.makeCall(call, expectedErrors)
  }

  private formatUrl(url: string): any {
    if (url.startsWith('https')) {
      return url
    } else {
      return this.baseUrl + url
    }
  }

  private formatHeaders(url: string): any {
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiToken}`
      }
  }

  private getRaw(url: string) {
    let getUrl = this.formatUrl(url)
    return fetch(getUrl, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.formatHeaders(url),
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
    });
  }

  private postRaw(url: string, data: any) {

    let postUrl = this.formatUrl(url)
    return fetch(postUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.formatHeaders(url),
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  }

  private makeCall(apiCall: Promise<Response>, expectedErrors: Array<string>): Promise<ApiCallResponse> {
    return new Promise((resolve, reject) => {
      apiCall
        // We got an http response
        .then((response) => {

          const result: ApiCallResponse = {
            response: response,
            isExpectedError: false,
          };

          if (response.ok) {
            // Call was successful (2XX status code)
            response.json().then(json => {
              // Uncomment to debug. Should be removed in production.
              // console.log("----> ", json)

              result.json = json
              resolve(result);
            })
              .catch(() => {
                // This is for status 204 which will have an empty body (ie no json)
                resolve(result);
              });
          } else {
            // We got an error (most likely 4XX or 5XX status code)
            response.json().then(json => {
              result.json = json
              result.errorName = result.json.name;
              result.errorMessage = result.json.message;
              result.isExpectedError = expectedErrors.includes(result.errorName!)
              if (!result.isExpectedError) {
                if (response.status === 401) {
                  // User session has most likely expired server side
                  alert("Permission denied: 401")
                } else {
                  alert('Error ' + response.status)
                }
              }
              reject(result)
            })
              .catch(() => {
                // Response body is not json
                alert('Error 1:' + response.status)
                reject(result);
              });
          }
        })

        // We got no http response (eg due to timeout or network error)
        .catch(reason => {
          alert('Error 2: ' + reason)
        });
    });
  }
}