import React from 'react';
import checkIcon from 'shared-assets/icons/neutral-800/check.svg';

export interface Step {
  title: string;
  description?: string;
  content: React.ReactNode;
}

export interface StepperProps {
  steps: Step[];
  currentIndex: number;
  completedSteps?: number[];
  direction?: 'horizontal' | 'vertical';
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  currentIndex,
  completedSteps = [],
  direction = 'horizontal',
}) => {

  if (!steps || steps.length === 0) {
    return null;
  }

  const circleSize = 28; // size-7 is approximately 28px
  const circleCenterOffset = circleSize / 2; // 14px

  const getCircleClasses = (isActive: boolean, isCompleted: boolean) => {
    let classes =
      'size-7 flex justify-center items-center font-medium rounded-full z-10 ';

    if (isActive) {
      classes += 'bg-success-300 text-neutral-900';
    } else if (isCompleted) {
      classes += 'bg-success-500 text-neutral-900';
    } else {
      classes += 'bg-neutral-700 text-neutral-400';
    }

    return classes;
  };

  const getStepClasses = (isActive: boolean, isCompleted: boolean, vertical: boolean) => {
    let classes = 'relative flex ';
    classes += vertical ? 'flex-row' : 'flex-col';

    if (vertical) {
      // In vertical mode, we align items to the start (left side)
      classes += ' justify-start items-start';
    } else {
      // Horizontal mode
      classes += ' justify-start flex-1 items-center';
    }

    if (isActive) {
      classes += ' active';
    } else if (isCompleted) {
      classes += ' success';
    }

    return classes;
  };

  const isLineCompleted = (index: number) => completedSteps.includes(index);

  const getLineClasses = (completed: boolean) => {
    return completed ? 'bg-success-500' : 'bg-neutral-700';
  };

  // HORIZONTAL MODE
  if (direction === 'horizontal') {
    return (
      <div className="w-full">
        <ul className="relative flex items-center justify-between w-full">
          {steps.map((step, index) => {
            const isActive = index === currentIndex;
            const isCompleted = completedSteps.includes(index) && !isActive;
            const titleClasses =
              'text-sm font-medium mt-2 ' +
              (isActive || isCompleted ? 'text-white' : 'text-white');
            const descriptionClasses =
              'text-sm font-normal mt-2 ' +
              (isActive || isCompleted ? 'text-neutral-400' : 'text-neutral-400');

            const leftLineCompleted = index > 0 ? isLineCompleted(index - 1) : false;
            const rightLineCompleted =
              index < steps.length - 1 ? isLineCompleted(index) : false;

            return (
              <li key={index} className={getStepClasses(isActive, isCompleted, false)}>
                <div className="relative flex items-center justify-center w-full h-7">
                  {index > 0 && (
                    <div
                      className={`absolute left-0 top-1/2 -translate-y-1/2 h-px ${getLineClasses(
                        leftLineCompleted
                      )}`}
                      style={{ width: '50%' }}
                    ></div>
                  )}

                  <span className={getCircleClasses(isActive, isCompleted)}>
                    {isCompleted ? (
                      <img src={checkIcon} alt="Checkmark" className="size-4" />
                    ) : (
                      <span>{index + 1}</span>
                    )}
                  </span>

                  {index < steps.length - 1 && (
                    <div
                      className={`absolute right-0 top-1/2 -translate-y-1/2 h-px ${getLineClasses(
                        rightLineCompleted
                      )}`}
                      style={{ width: '50%' }}
                    ></div>
                  )}
                </div>


                <span>
                  <div className={titleClasses}>{step.title}</div>
                  {step.description && <div className={descriptionClasses}>{step.description}</div>}
                </span>
              </li>
            );
          })}
        </ul>

        <div className="mt-6 sm:mt-8">
          {steps.map((step, index) => {
            const isActive = index === currentIndex;
            return (
              <div key={index} style={{ display: isActive ? 'block' : 'none' }}>
                {step.content}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // VERTICAL MODE
  return (
    <div className="w-full flex flex-col">
      <ul className="relative flex flex-col items-start w-full h-full">
        {steps.map((step, index) => {
          const isActive = index === currentIndex;
          const isCompleted = completedSteps.includes(index) && !isActive;
          const titleClasses =
            'text-sm font-medium ml-2 ' +
            (isActive || isCompleted ? 'text-white' : 'text-white');
            const descriptionClasses =
              'text-sm font-normal ml-2 ' +
              (isActive || isCompleted ? 'text-neutral-400' : 'text-neutral-400');

          const topLineCompleted = index > 0 ? isLineCompleted(index - 1) : false;
          const bottomLineCompleted = index < steps.length - 1 ? isLineCompleted(index) : false;

          return (
            <li
              key={index}
              className={`${getStepClasses(isActive, isCompleted, true)} relative grow w-full`}
            >

              {/* Lines are drawn absolutely inside the li */}
              {/* Top line: Only for steps after the first */}
              {index > 0 && (
                <div
                  className={`absolute left-[14px] top-0 w-px ${getLineClasses(topLineCompleted)}`}
                  style={{ height: circleCenterOffset }}
                ></div>
              )}

              {/* Bottom line: Only for steps before the last */}
              {index < steps.length - 1 && (
                <div
                  className={`absolute left-[14px] ${getLineClasses(bottomLineCompleted)}`}
                  style={{ top: circleCenterOffset, bottom: 0, width: 1 }}
                ></div>
              )}

              {/* Circle and title container */}
              <div className="relative flex items-center" style={{ marginTop: circleCenterOffset }}>
                <span className={getCircleClasses(isActive, isCompleted)}>
                  {isCompleted ? (
                    <img src={checkIcon} alt="Checkmark" className="size-4" />
                  ) : (
                    <span>{index + 1}</span>
                  )}
                </span>
                <span>
                  <div className={titleClasses}>{step.title}</div>
                  {step.description && <div className={descriptionClasses}>{step.description}</div>}
                </span>
              </div>
            </li>
          );
        })}
      </ul>

      <div className="mt-5 sm:mt-8 w-full flex-1 overflow-auto">
        {steps.map((step, index) => {
          const isActive = index === currentIndex;
          return (
            <div key={index} style={{ display: isActive ? 'block' : 'none', height: '100%' }}>
              {step.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
