const breadcrumbConfig = [
    { path: '/', breadcrumbKey: 'home' },
    { path: '/tasks', breadcrumbKey: 'tasks-nav-title' },
    { path: '/tasks/feedback/create', breadcrumbKey: 'feedback-create-review-questions' },
    { path: '/tasks/feedback/create/recipients', breadcrumbKey: 'feedback-recipients' },
    { path: '/tasks/feedback/create/recipients/summary', breadcrumbKey: 'feedback-create-summary' },
    { path: '/development-plan', breadcrumbKey: 'development-plan-nav-title' },
    { path: '/development-plan/focus-area/:id', breadcrumbKey: 'focus-area-nav-title' },
    { path: '/accept_invite/:code', breadcrumbKey: 'accept-invite' },
    { path: '/feedback/questionnaire/:id', breadcrumbKey: 'feedback-questionnaire' },
    { path: '/feedback', breadcrumbKey: 'feedback-title' },
    { path: '/feedback/result/:id', breadcrumbKey: 'feedback-one-breadcrumb' },
    { path: '/about', breadcrumbKey: 'about-nav-title' },
    { path: '/support', breadcrumbKey: 'support-nav-title' },
    { path: '/calendar', breadcrumbKey: 'calendar-nav-title' },
  ];
  
  export default breadcrumbConfig;