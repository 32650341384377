import React from 'react';


function MaskedIcon({ src, className }: { src: string, className?: string }) {

  const classes = `
    mask-svg
    group-hover:text-neutral-400
    ${className}
  `;

  return (
    <span
      className={classes}
      style={{
        maskImage: `url(${src})`,
        WebkitMaskImage: `url(${src})`,
      }}
    />
  );
}

export default MaskedIcon;