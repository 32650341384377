// packages/shared-components/src/components/Navbar/Navbar.tsx

import React, { useEffect } from 'react';

import logoutIcon from 'shared-assets/icons/logout.svg';
import menuIcon from 'shared-assets/icons/menu.svg';

interface MenuItem {
  id: number;
  title: string;
  onClick: () => void;
  icon: React.ReactNode;
  divider?: boolean;
}

interface NavbarProps {
  icon?: string;
  showMenu?: boolean;
  login: () => void;
  logout: () => void;
  t: (key: string) => string;
  i18n: any; // Replace 'any' with the correct type if available
  menuItems: MenuItem[];
}

const Navbar: React.FC<NavbarProps> = ({
  icon,
  showMenu = true,
  login,
  logout,
  t,
  i18n,
  menuItems,
}) => {

  const combinedMenuItems = menuItems;

  const generalClasses =
    'flex flex-col p-4 desktop:p-6 transition-all duration-300 transform fixed top-0 right-0 bottom-0 z-[60]';
  const widthPositionClasses = 'max-desktop:w-full w-96';
  const backgroundBorderClasses = 'bg-neutral-800';
  const overflowScrollbarClasses =
    'overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-neutral-300 scrollbar-track-neutral-200';
  const darkOverflowScrollbarClasses =
    'dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500';
  const overlayClasses = 'hs-overlay hs-overlay-open:translate-x-0 translate-x-full';
  const combinedClasses = `${generalClasses} ${widthPositionClasses} ${backgroundBorderClasses} ${overflowScrollbarClasses} ${overlayClasses} ${darkOverflowScrollbarClasses}`;

  useEffect(() => {
    // Retrieve the language from localStorage if it exists
    const savedLang =
      localStorage.getItem('language') ||
      navigator.language ||
      (navigator as any).userLanguage;
    i18n.changeLanguage(savedLang);
    localStorage.setItem('language', savedLang);
  }, [i18n]);
  
  return (
    <header className="w-full bg-neutral-900 text-sm relative">
      <div className="flex justify-between flex-nowrap w-full p-4 desktop:p-6">
        <a
          className="flex flex-col justify-center text-xl font-semibold ext-white"
          href={showMenu ? "/" : "https://www.zebrain.se"}
          aria-label="Brand"
        >
          <img
            src={icon}
            alt="logo"
          />
        </a>
        {showMenu && (
          <div className="flex flex-col justify-center ml-auto">
            <button
              type="button"
              className="text-gray-500 hover:text-gray-600"
              data-hs-overlay="#docs-sidebar"
              aria-controls="docs-sidebar"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle Navigation</span>
              <img src={menuIcon} alt="menu" />
            </button>
          </div>
        )}
        {showMenu && (
          <div id="docs-sidebar" className={combinedClasses}>
            <div className="flex justify-between items-center mb-4">
              <a
                className="flex-none text-xl font-semibold dark:text-white"
                href="/"
                aria-label="Brand"
              >
                <img
                  src={icon}
                  alt="logo"
                />
              </a>
              <button
                type="button"
                className="text-white hover:text-white"
                data-hs-overlay="#docs-sidebar"
                aria-controls="docs-sidebar"
                aria-label="Close navigation"
              >
                <span className="sr-only">Close Navigation</span>
                {/* Replace with your close icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <nav
              className="hs-accordion-group w-full flex flex-col justify-between flex-grow"
              data-hs-accordion-always-open
            >
              <div className="grid gap-4 w-full">
                {combinedMenuItems.map((item) => (
                  <div key={item.id}>
                    <div
                      className="flex items-center gap-x-4 p-2 text-gray-700 text-base font-medium hover:text-gray-800 dark:text-white dark:hover:text-white cursor-pointer dark:hover:text-white dark:hover:bg-neutral-700 hover:rounded-[6px]"
                      onClick={() => {
                        item.onClick();
                        // Close the sidebar
                        const overlayElement = document.getElementById('docs-sidebar');
                        if (overlayElement) {
                          overlayElement.classList.remove('hs-overlay-open');
                          overlayElement.classList.add('translate-x-full');
                        }
                      }}
                      data-hs-overlay="#docs-sidebar"
                      aria-controls="docs-sidebar"
                      aria-label="Close navigation"
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </div>
                    {item.divider && <hr className="border-neutral-600 mt-4" />}
                  </div>
                ))}
              </div>

              {/* Logout button as a footer inside the navbar */}
              <div className="w-full">
                <hr className="border-neutral-600 w-full mb-4" />
                <div
                  className="flex items-center gap-x-4 p-2 text-gray-700 font-medium text-base hover:text-gray-800 dark:text-white dark:hover:text-white cursor-pointer w-full"
                  onClick={logout}
                >
                  <img src={logoutIcon} alt="logout" />
                  <span>{t('logout')}</span>
                </div>
              </div>
            </nav>
          </div>
        )}
      </div>
      <hr className="border-neutral-700" />
    </header>
  );
};

export default Navbar;