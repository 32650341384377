import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "home": "Home",
                "all": "All",
                "start": "Start",
                "invalid-email": "Invalid email address. Please enter a correct email address",
                "next": "Next",
                'deadline': 'Deadline',
                "continue": "Continue",
                "time-ago-now": "now",
                "send": "Send",
                "submit": "Submit",
                "search-email": "Search email",
                "chat-placeholder": "Type your message here...",
                "chat-send": "Send",
                "chat-start": "Get started",
                "chat": "Chat",
                "menu-chat": "Chat with Emma",
                "menu-my-tasks": "My tasks",
                "menu-development-plan": "My development plan",
                "menu-my-account": "My account",
                "menu-support": "Support",
                "menu-about-company": "About Zebrain",
                "task": "Task",
                "description": "Description",
                "logout": "Log out",
                "tasks-page-title": "Select task to work on",
                "auto-task-chat-message": "I want to work on the task",
                "development-area-page-title": "Select focus area",
                "focus-area-quick-select": "Quick select",
                "focus-area-start-chat": "I want to start coaching within the focus area",
                "focus-area-start-chat-button": "Start coaching session",
                "focus-area-learn-more-button": "Learn more about the topic",
                "chat-nav-title": "Chat",
                "tasks-nav-title": "My tasks",
                "ongoing": "Ongoing",
                "completed": "Completed",
                "completed-tasks": "Completed tasks",
                "development-plan-nav-title": "My development plan",
                "focus-area-nav-title": "Focus Area",
                "no-tasks": "You have no tasks yet! Start working on a Focus Area and let AI Coach Emma create it for you.",
                "no-development-areas": "You have not created any focus areas.",
                "chat-welcome": "Hi, nice to meet you here!\nI'm your AI coach Emma, let's start with a short introduction.",
                "support-nav-title": "Support",
                "support-page-title": "What can we help you with?",
                "support-description-placeholder": "Describe your issue here...",
                "support-email-info": "You can also email us at",
                "support-modal-success-title": "Thank you for reaching out to us! ",
                "support-modal-success-description": "We will get back to you as soon as possible.",
                "support-error-message": "An error occurred, please try again later.",
                "about-nav-title": "About Zebrain",
                "feedback-nav-title": "Feedback",
                "feedback-title": "Feedback and insights",
                "feedback-one-breadcrumb": "Result",
                "menu-feedback": "Feedback and insights",
                "request-feedback": "Request feedback",
                "received-feedback": "Received feedback",
                "choose-feedback": "Choose what to get feedback on",
                "create-feedback": "Create feedback",
                "feedback-create-review-questions": "Create feedback questions",
                "feedback-select-recipients": "Select recipients",
                "feedback-recipients": "Recipients",
                "feedback-recipients-add": "Add recipients",
                "feedback-results": "Feedback results",
                "other-subject": "Other subject",
                "create-review-question": "Create review question",
                "feedback-create-summary": "Summary",
                "feedback-regenerate-question": "Regenerate question",
                "feedback-input-placeholder": "Write text here...",
                "feedback-add-question": "Add question",
                "select-all": "Select all",
                "send-request": "Send request",
                "feedback-change-question": "Describe how you would like to change the question",
                "feedback-review-questions": "Review questions",
                "feedback-request-sent": "Feedback request sent",
                "feedback-summary-modal-body": "Your feedback request is sent. You will get a notification when you recieve feedback on Feedback page.",
                "feedback-recipients-alert": "Select at least {{number}} recipient(s)",
                "feedback-recipients-summary-alert": "Go back to select at least 1 recipients",
                "feedback-create-question-alert": "Go back to create at least 1 review question",
                "feedback-is-anonymous": "All responses remain anonymous.",
                "received": "Received answers",
                "feedback-out-of": "feedback out of",
                "out-of": "out of",
                "responses": "responses",
                "feedback-number-of-feedbacks": "recipients have submitted feedback.",
                "feedback-time-left": "The deadline for submissions is",
                "feedback-sent": "Feedback sent",
                "feedback-selected-recipients": "Selected recipients",
                "feedback-no-selected-recipients": "No selected recipients",
                "feedback-response-form-alert": "Please fill in all fields",
                "feedback-recipientpage-questions-alert": "It looks like you've deleted all the review questions. Before you can select recipients, you'll need to create new questions.",
                "requested-from": "Request sent to",
                "already-submitted": "Already submitted",
                "already-submitted-message": "You have already submitted feedback",
                "thank-you-for-your-feedback": "Thanks for taking your time!",
                "self-assessment-submission-ok": "Great! We’ll wait for the feedback to arrive and notify you once the state analysis is ready.",
                "self-assessment-sent": "Self-assessment completed",
                "back-to-tasks": "Back to My tasks",
                "question": "Question",
                "created-at": "Created",
                "respondents": "Respondents",
                "your-feedback": "Received feedback",
                "feedback-vs-self-assessment": "Feedback and self-assessment",
                "asking-for-feedback": "is asking for your feedback",
                "strongly-agree": "Strongly agree",
                "agree": "Somewhat agree",
                "neutral": "Neither agree nor disagree",
                "disagree": "Somewhat disagree",
                "strongly-disagree": "Strongly disagree",
                "selected": "Selected",
                "no-selected-recipients": "You haven't selected any recipients",
                "no-available-recipients": "No available recipients",
                "feedback-request-sent-description": "Your feedback request is sent. Received feedback will appear on the Feedback page.",
                "edit-question": "Edit question",
                "back-to": "Back to {{page}}",
                "create-questions-alert": "Create at least 3 feedback questions",
                "create-questions": "Create questions",
                "select-recipients": "Select recipients",
                "questions": "Questions",
                "recipients": "Recipients",
                "summary": "Summary",
                "leave-question": "Do you want to leave the page?",
                "leave": "Leave",
                "stay": "Stay",
                "delete-question": "Delete question?",
                "back": "Back",
                "feedback-create-success-text": "You can start your self-assessment now or access it later from the Focus Area page.",
                "feedback-create-success-text-task": "As an optional step you can also go through self-assessment to compare it to feedback.",
                "start-self-assessment": "Start self-assessment",
                "no-answer": "Can’t answer/evaluate",
                "answer-options": "Answer options",
                "proceed-with": "Proceed with",
                "get-coaching-on-focus-area": "Get coaching on the focus area",
                "answers": "Answers",
                "not-answered": "Can't answer/evaluate",
                "loading-data": "Loading data...",
                "get-started": "Get started",
                "not-included": "Not included",
                "discuss-analysis-results": "Get coaching on your analysis",
                "see-all-insights": "Explore your feedback",
                "help-me-analyze-feedback": "Help me analyse the feedback and self-assessment results for this review question",
                "self-assessment": "Self-assessment",
                "complete-self-assessment": "Complete self-assessment",
                "self-assessment-txt1": "Self-assessment allows you to reflect on your own performance.",
                "self-assessment-txt2": "Answer the questions you created in the previous step from your own perspective.",
                "self-assessment-txt3": "Be honest with yourself to get the most valuable insights.",
                "self-assessment-txt4": "Once you’re done, click Submit.",
                "start-analysis": "Start analysis",
                "start-analysis-intro": "Get clear on your current state. Do a self-assessment and ask colleagues for feedback to uncover insights, reveal blind spots and gain clarity on your areas to grow.",
                "read-more": "Read more",
                "read-less": "Read less",
                "current-state": "Current state",
                "current-state-analysis": "Current state analysis",
                "understanding-your-scores": "Understanding your scores",
                "average-of-your-answers": "This is your own answer, as self-assessed by you.",
                "feedback-score": "Feedback",
                "average-feedback-colleagues": "The average of feedback from your colleagues.",
                "perception-gap": "Perception gap",
                "difference-between-above": "The perception gap is the difference between your self-assessment and the average of the feedback you received from others",
                "striped-areas-represents": "The striped area within the circle represents your growth potential, up to maximum score (5.0).",
                "answer-score-correlation": "Answer option and score",
                "focus-areas": "Focus areas",
                "focus-area": "Focus Area",
                "tasks": "Tasks",
                "discuss-this-result": "Get coaching on your result",
                "you": "You",
                "why-no-answer": "Why someone didn’t answer",
                "why-no-answer-1": "Limited contact or difficult to recall",
                "why-no-answer-2": "Respondents have not worked closely with you or known you long enough to provide feedback. They cannot recall sufficient details or specific examples to answer.",
                "why-no-answer-3": "Unclear or irrelevant question",
                "why-no-answer-4": "The question was too difficult to understand, lacked sufficient context or was not relevant to the respondent’s experience or relationship with you.",
                "why-no-answer-5": "Important to Note",
                "why-no-answer-6": "We’re not including these answers into the score. However, if many respondents choose these options, it may indicate a need to select respondents who have had more interaction with you, know you better or rephrase questions to make them clearer and more relevant to the respondent.",
                "cancel": "Cancel",
                "delete": "Delete",
                "no-feedback-yet": "No feedback yet",
                "send-request-from-focus-area": "Send a request from a Focus Area to get started",
                "send-request-from-task": "Send a request from a task to get started",
                "feedback-will-appear-focus-area": "Feedback will appear here once the deadline passes or all recipients respond.",
                "feedback-will-appear-task": "Feedback will appear here once we receive the first response.",
                "go-to-focus-area": "Go to My Development plan",
                "go-to-tasks": "Go to My tasks",
                "no-completed-tasks-yet": "You have no completed tasks yet!",
                "no-ongoing-tasks": "You have no ongoing tasks! Start working on a Focus Area and let AI Coach Emma create it for you.",
                "request-created-at": "Request created",
                "discuss-analysis-result": "I want to discuss my current state analysis result:",
                "analysis-summary": "Analysis",
                "waiting-for-feedback": "Waiting for feedback",
                "waiting-for-feedback-and-self": "Waiting for feedback and self-assessment",
                "waiting-for-feedback-info": "Please note we won't be able to provide current state analysis before you complete this step",
                "feedback-request": "Feedback request",
                "sent-on": "Sent on",
                "to-analyse-steps": "In order to analyse your current state, please go through the next steps:",
                "see-feedback": "See feedback",
                "last-updated": "Last updated",
                "how-we-calculate-score": "How we calculate the scores",
                "how-we-calculate-text": "We use exact values for calculations but display the rounded ones. This is why you might occasionally notice a gap that doesn’t match the exact subtraction of the two scores.",

                // ERROR
                "deadline-passed": "The deadline has passed",
                "deadline-passed-message": "Sorry, this feedback request is already closed and we are no longer accepting responses. If you have any questions, please contact",
            },
        },
        sv: {
            translation: {
                "home": "Hem",
                "all": "Alla",
                "start": "Start",
                "invalid-email": "Ogiltig e-postadress. Ange en korrekt e-postadress",
                "next": "Nästa",
                'deadline': 'Slutdatum',
                "continue": "Fortsätt",
                "send": "Skicka",
                "submit": "Skicka",
                "search-email": "Sök e-postadress",
                "time-ago-now": "nu",
                "chat-placeholder": "Skriv ditt meddelande här...",
                "chat-send": "Skicka",
                "chat-start": "Kom igång",
                "chat": "Chatt",
                "menu-chat": "Chatta med Emma",
                "menu-my-tasks": "Mina uppgifter",
                "menu-development-plan": "Min utvecklingsplan",
                "menu-my-account": "Mitt konto",
                "menu-support": "Support",
                "menu-about-company": "Om Zebrain",
                "task": "Uppgift",
                "description": "Beskrivning",
                "logout": "Logga ut",
                "tasks-page-title": "Välj uppgift att arbeta med",
                "auto-task-chat-message": "Jag vill arbeta med uppgiften",
                "development-area-page-title": "Välj fokusområde",
                "focus-area-quick-select": "Snabbval",
                "focus-area-start-chat": "Jag vill starta coaching inom fokusområdet",
                "focus-area-start-chat-button": "Starta coachsession",
                "focus-area-learn-more-button": "Lär mig mer om ämnet",
                "chat-nav-title": "Chatt",
                "tasks-nav-title": "Mina uppgifter",
                "ongoing": "Pågående",
                "completed": "Färdiga",
                "completed-tasks": "Färdiga uppgifter",
                "development-plan-nav-title": "Min utvecklingsplan",
                "focus-area-nav-title": "Fokusområde",
                "no-tasks": "Du har inga uppgifter än. Böjar arbeta med ett fokusområde och låt AI-coachen Emma skapa en till dig.",
                "no-development-areas": "Du har inte skapat några fokusområden.",
                "chat-welcome": "Hej, vad kul att träffa dig här!\nJag är din AI coach Emma, låt oss börja med en kort introduktion.",
                "support-nav-title": "Support",
                "support-page-title": "Vad kan vi hjälpa dig med?",
                "support-description-placeholder": "Beskriv ditt problem här...",
                "support-email-info": "Du kan också mejla oss på",
                "support-modal-success-title": "Tack för att du kontaktar oss! ",
                "support-modal-success-description": "Vi återkommer så snart som möjligt.",
                "support-error-message": "Ett fel uppstod, försök igen senare.",
                "about-nav-title": "Om Zebrain",
                "menu-feedback": "Feedback och insikter",
                "feedback-nav-title": "Feedback",
                "feedback-title": "Feedback och insikter",
                "feedback-one-breadcrumb": "Resultat",
                "request-feedback": "Be om feedback",
                "received-feedback": "Mottagen feedback",
                "create-feedback": "Skapa återkoppling",
                "feedback-create-review-questions": "Skapa feedbackfrågor",
                "feedback-select-recipients": "Välj mottagare",
                "feedback-recipients": "Mottagare",
                "feedback-recipients-add": "Lägg till mottagare",
                "feedback-results": "Återkopplingsresultat",
                "choose-feedback": "Välj vad du vill få återkoppling på",
                "other-subject": "Övrigt ämne",
                "create-review-question": "Skapa återkopplingsfråga",
                "feedback-create-summary": "Sammanfattning",
                "feedback-regenerate-question": "Ändra fråga",
                "feedback-input-placeholder": "Beskriv din ändring...",
                "feedback-add-question": "Lägg till fråga",
                "select-all": "Välj alla",
                "send-request": "Skicka förfrågan",
                "feedback-change-question": "Beskriv hur du skulle vilja ändra frågan",
                "feedback-review-questions": "Feedbackfrågor",
                "feedback-request-sent": "Förfrågan skickad",
                "feedback-summary-modal-body": "Din återkopplingsförfrågan är skickad. Du kommer få en notifikation när du får återkoppling på Återkopplingssidan.",
                "feedback-recipients-alert": "Välj minst {{number}} mottagare",
                "feedback-recipients-summary-alert": "Gå tillbaka och välj minst 1 mottagare",
                "feedback-create-question-alert": "Gå tillbaka och skapa minst en feedbackfråga",
                "feedback-is-anonymous": "Alla svar förblir anonyma.",
                "feedback-number-of-feedbacks": "mottagare har lämnat feedback.",
                "feedback-time-left": "Sista datum för inlämning är den",
                "feedback-sent": "Feedback skickad",
                "feedback-selected-recipients": "Valda mottagare",
                "feedback-no-selected-recipients": "Inga valda mottagare",
                "feedback-response-form-alert": "Vänligen fyll i alla fält",
                "feedback-recipientpage-questions-alert": "Det verkar som att du har raderat alla feedbackfrågor. Innan du kan välja mottagare behöver du skapa nya frågor.",
                "requested-from": "Förfrågan skickad till",
                "already-submitted": "Redan skickat",
                "already-submitted-message": "Du har redan skickat feedback",
                "thank-you-for-your-feedback": "Tack för att du tog dig tid!",
                "self-assessment-submission-ok": "Bra! Vi meddelar dig när feedback har samlats in och är redo att analyseras.",
                "self-assessment-sent": "Självskattning färdig",
                "received": "Mottagna svar",
                "feedback-out-of": "återkopplingar av",
                "out-of": "av",
                "responses": "svar",
                "back-to-tasks": "Tillbaka till Mina uppgifter",
                "question": "Fråga",
                "created-at": "Skapad",
                "respondents": "Deltagare",
                "your-feedback": "Mottagen feedback",
                "feedback-vs-self-assessment": "Feedback och självskattning",
                "feedback-received": "Mottagen feedback",
                "asking-for-feedback": "ber om din feedback",
                "strongly-agree": "Håller helt med",
                "agree": "Håller delvis med",
                "neutral": "Varken eller",
                "disagree": "Håller inte med",
                "strongly-disagree": "Håller inte alls med",
                "selected": "Valda",
                "no-selected-recipients": "Du har inte valt några mottagare",
                "no-available-recipients": "Inga tillgängliga mottagare",
                "feedback-request-sent-description": "Din feedbackförfrågan har skickats. Mottagen feedback kommer synas på Feedbacksidan.",
                "edit-question": "Redigera fråga",
                "back-to": "Tillbaka till {{page}}",
                "create-questions-alert": "Skapa minst 3 feedbackfrågor",
                "create-questions": "Skapa frågor",
                "select-recipients": "Välj mottagare",
                "questions": "Frågor",
                "recipients": "Mottagare",
                "summary": "Sammanfattning",
                "leave-question": "Vill du lämna sidan?",
                "leave": "Lämna",
                "stay": "Stanna",
                "delete-question": "Radera fråga?",
                "back": "Tillbaka",
                "feedback-create-success-text": "Du kan börja din självutvärdering nu eller komma åt den senare från fokusområdessidan.",
                "feedback-create-success-text-task": "Om du vill kan du göra en självskattning och jämföra med feedback.",
                "start-self-assessment": "Påbörja självskattning",
                "no-answer": "Kan inte svara/utvärdera",
                "answer-options": "Svarsalternativ",
                "proceed-with": "Fortsätt med",
                "get-coaching-on-focus-area": "Få coaching på fokusområdet",
                "answers": "Svar",
                "not-answered": "Kan inte svara/utvärdera",
                "loading-data": "Laddar data...",
                "get-started": "Kom igång",
                "not-included": "Ej inkluderat",
                "discuss-analysis-results": "Få coaching på din analys",
                "see-all-insights": "Utforska mer av din feedback",
                "help-me-analyze-feedback": "Hjälp mig analysera feedback och självskattning för denna fråga",
                "self-assessment": "Självskattning",
                "complete-self-assessment": "Färdigställ självskattning",
                "self-assessment-txt1": "Självskattning låter dig reflektera över din egen prestation.",
                "self-assessment-txt2": "Svara på frågorna du skapade i föregående steg utifrån ditt eget perspektiv.",
                "self-assessment-txt3": "Var ärlig så får du mer värdefulla insikter.",
                "self-assessment-txt4": "När du är klar klickar du Skicka.",
                "start-analysis": "Starta analys",
                "start-analysis-intro": "Få koll på ditt nuläge. Gör din självskattning och be kollegor om feedback för att få insikter och nuläget inom ditt utvecklingsområde.",
                "read-more": "Läs mer",
                "read-less": "Läs mindre",
                "current-state": "Nuläge",
                "current-state-analysis": "Analys av nuläge",
                "understanding-your-scores": "Förstå ditt resultat",
                "average-of-your-answers": "Det här är ditt eget svar som du själv har skattat.",
                "feedback-score": "Feedback",
                "average-feedback-colleagues": "Genomsnitt av feedback från dina kollegor.",
                "perception-gap": "Gap",
                "difference-between-above": "Gapet är skillnaden mellan din självskattning och genomsnittet av den feedbackpoäng du fått från andra",
                "striped-areas-represents": "Det randiga området i cirkeln visar din potential upp till maximal poäng (5,0).",
                "answer-score-correlation": "Svarsalternativ och poäng",
                "focus-areas": "Fokusområden",
                "focus-area": "Fokusområde",
                "tasks": "Uppgifter",
                "discuss-this-result": "Få coaching på ditt resultat",
                "you": "Du",
                "why-no-answer": "Varför någon inte svarade",
                "why-no-answer-1": "Begränsad kontakt eller svårt att minnas",
                "why-no-answer-2": "Respondenter har inte jobbat nära dig eller känt dig tillräckligt länge för att kunna ge feedback. De minns inte tillräckligt med detaljer eller specifika exempel för att kunna svara.",
                "why-no-answer-3": "Otydlig eller Irrelevant fråga",
                "why-no-answer-4": "Frågan var för svår att förstå, saknade tillräcklig kontext eller var inte relevant för respondentens erfarenhet eller relation till dig.",
                "why-no-answer-5": "Observera",
                "why-no-answer-6": "Vi inkluderar inte dessa svar i poängen, men om många väljer detta alternativ kan det betyda att du behöver välja respondenter som du har haft mer kontakt med, som känner dig bättre eller omformulera frågor för att göra dem enklare att förstå och mer relevanta för respondenten.",
                "cancel": "Avbryt",
                "delete": "Radera",
                "no-feedback-yet": "Ingen feedback än",
                "send-request-from-focus-area": "Skicka en förfrågan från ett fokusområde för att komma igång",
                "send-request-from-task": "Skicka en förfrågan från en uppgift för att komma igång",
                "feedback-will-appear-focus-area": "Feedback kommer att visas här så snart deadline har passerats eller alla har svarat.",
                "feedback-will-appear-task": "Feedback kommer att visas här så snart vi fått in det första svaret.",
                "go-to-focus-area": "Gå till min utvecklingsplan",
                "go-to-tasks": "Gå till mina uppgifter",
                "no-completed-tasks-yet": "Du har inga färdiga uppgifter än!",
                "no-ongoing-tasks": "Du har inga pågående uppgifter! Börjar arbeta på ett fokusområde och let AI-coach Emma skapa en uppgift åt dig.",
                "request-created-at": "Förfrågan skapad",
                "discuss-analysis-result": "Jag vill diskutera analysresultatet för min nuvarande status:",
                "analysis-summary": "Analys",
                "waiting-for-feedback": "Väntar på feedback",
                "waiting-for-feedback-and-self": "Väntar på feedback och självskattning",
                "waiting-for-feedback-info": "Du behöver göra din självskattning för att få din nulägesanalys",
                "feedback-request": "Feedback-förfrågan",
                "sent-on": "Skickat",
                "to-analyse-steps": "För att analysera ditt nuläge, genomför följande steg:",
                "see-feedback": "Se feedback",
                "last-updated": "Senast uppdaterad",
                "how-we-calculate-score": "Så beräknar vi poängen",
                "how-we-calculate-text": "Vi använder exakta värden i beräkningarna, men visar de avrundade resultaten. Därför kan det ibland uppstå en skillnad som inte exakt motsvarar subtraktionen mellan två poäng.",


                // ERROR
                "deadline-passed": "Deadline har passerat",
                "deadline-passed-message": "Tyvärr är denna feedbackförfrågan redan stängd och vi tar inte längre emot svar. Om du har några frågor, vänligen kontakta support@zebrain.se",
            },
        },
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;