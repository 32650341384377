
import React from 'react';

interface Props {
  type?: string;
  value1: number;
  value2: number;
  maxValue: number;
  circleNumbers?: boolean
}


const HalfCircleChart: React.FC<Props> = ({ value1, value2, maxValue, type, circleNumbers }) => {
    const R_MAX = 50; // Maximum radius based on viewBox
    const cx = 50;    // Center x-coordinate
    const cy = 50;    // Center y-coordinate
  
    // Calculate radii based on area proportionality
    // const radius1 = R_MAX * Math.sqrt(value1 / maxValue);
    // const radius2 = R_MAX * Math.sqrt(value2 / maxValue);

    // Calculate radii based on radius proportionality
    // HD - use radius proportional with value to see difference clearer
    const radius1 = R_MAX * value1 / maxValue;
    const radius2 = R_MAX * value2 / maxValue;

    const leftFill = type && type === "task" ? "#5B6EF4": "#FF9B8A"
    const rightFill = type && type === "task" ? "#8C99F7": "#FFB9AD"
  
    // Left half-circle path
    const leftHalfCirclePath = (radius: number) => `
      M ${cx},${cy}
      m 0,${-radius}
      a ${radius},${radius} 0 1,0 0,${2 * radius}
      L ${cx},${cy}
      Z
    `;
  
    // Right half-circle path
    const rightHalfCirclePath = (radius: number) => `
      M ${cx},${cy}
      m 0,${-radius}
      a ${radius},${radius} 0 1,1 0,${2 * radius}
      L ${cx},${cy}
      Z
    `;
  
    // Minimum radius to display text (optional, for better visuals)
    const MIN_RADIUS_FOR_TEXT = 10;
  
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}
      >
        <defs>
          {/* Define the striped pattern with transparent background */}
          <pattern
            id="stripedPattern"
            patternUnits="userSpaceOnUse"
            width="2"
            height="8"
            patternTransform="rotate(-45)"
          >
            {/* Stripes colored #FFCDC4 */}
            <rect width="0.1" height="8" fill="#FFCDC4" />
          </pattern>
        </defs>
  
        {/* Outer Circle with Striped Pattern */}
        <circle cx={cx} cy={cy} r={R_MAX} fill="url(#stripedPattern)" />
  
        {/* Left Half-Circle */}
        <path d={leftHalfCirclePath(radius1)} fill={leftFill} />
  
        {/* Right Half-Circle */}
        <path d={rightHalfCirclePath(radius2)} fill={rightFill} />
  
        {/* Text for Left Half-Circle */}
        {(circleNumbers && radius1 > MIN_RADIUS_FOR_TEXT) && (
          <text
            x={cx - radius1 / 2}
            y={cy}
            fill="var(--neutral-900, #000026)"
            fontFamily='"TT Hoves"'
            fontSize="9px"
            fontWeight="500"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {value1.toFixed(1)}
          </text>
        )}
  
        {/* Text for Right Half-Circle */}
        {(circleNumbers && radius2 > MIN_RADIUS_FOR_TEXT) && (
          <text
            x={cx + radius2 / 2}
            y={cy}
            fill="var(--neutral-900, #000026)"
            fontFamily='"TT Hoves"'
            fontSize="9px"
            fontWeight="500"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {value2.toFixed(1)}
          </text>
        )}
      </svg>
    );
  };

  
  export default HalfCircleChart;