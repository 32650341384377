import React, { useState } from 'react';
import closeIcon from 'shared-assets/icons/close.svg';
import chevronDownIcon from 'shared-assets/icons/chevronDown.svg';
import sendIcon from 'shared-assets/icons/send.svg';
const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();
import { CustomCard as Card, CardBody, CardFooter, Input } from 'shared-components';

interface QuestionCardProps {
    question: any;
    removeQuestion: () => void;
    answerOptions: any;
    updateQuestion: (questionId: string) => void;
    setInputValueMap: (value: any) => void;
    index: number;
    t: any;
    showFooter?: boolean;
    removable?: boolean;
}

const QuestionCard = ({ question, removeQuestion, answerOptions, updateQuestion, setInputValueMap, index, t, showFooter=true, removable=true }: QuestionCardProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <Card>
            <CardBody>
                <div className="flex flex-row gap-4 justfify-between bg-neutral-800 rounded-md p-4">
                    {/* Question info */}
                    <div className="w-full">
                        {/* Question title */}
                        <div>
                            <h3 className="text-lg font-medium">{t('question')} {index+1}</h3>
                        </div>

                        <div className="flex flex-col gap-2">
                            {/* Question description */}
                            <div>
                                <p className="text-neutral-400">
                                    {question.description}
                                </p>
                            </div>

                            {/* Answer input */}
                            <div>
                                <p className="text-base font-medium">{t('answer-options')}</p>
                                <div className="flex flex-col gap-1">
                                    {question.type === "AgreementScale" && answerOptions.toReversed().map((option, idx) => (
                                        <div className="flex" key={`question-${idx}`}>
                                            <ul className="flex gap-2 list-disc list-inside text-neutral-400">
                                                <li className="text-sm font-medium">{option}</li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Delete question */}
                    {removable && <div className="min-w-4">
                        <img
                            src={closeIcon}
                            alt="Close"
                            className="cursor-pointer h-4 w-4"
                            onClick={removeQuestion}
                        />
                    </div>}
                </div>
            </CardBody>
            {showFooter && <CardFooter>
                <div className={`flex flex-col`}>
                    <div className="flex justify-between items-center cursor-pointer"
                         onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <p className="text-base font-medium">{t("edit-question")}</p>
                        {/* Dropdown button to toggle visibility */}
                        <img
                            src={chevronDownIcon}
                            alt="Toggle dropdown"
                            className={`h-4 w-4 cursor-pointer transform transition-transform duration-300 ${
                                isDropdownOpen ? 'rotate-180' : ''
                            }`}

                        />
                    </div>
                    
                    <div
                        className={`flex flex-col overflow-hidden transition-all duration-300 gap-2 ${
                            isDropdownOpen ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
                        }`}
                    >
                        <div className="mt-2">
                            <Input
                                key={`update-question-${index}`}
                                placeholder={t('feedback-input-placeholder')}
                                bgClass="bg-neutral-800"
                                onSubmit={(event) => {
                                    if (event.key === 'Enter') {
                                        updateQuestion(question.id);
                                    }
                                }}
                                onChange={(value) => {
                                    setInputValueMap((prev) => ({
                                        ...prev,
                                        [question.id]: value,
                                    }));
                                }}
                                leftIcon={<img src={emmaIcon} alt="Emma" className="w-6 h-6" />}
                                rightIcon={
                                    <img
                                        src={sendIcon}
                                        alt="Send"
                                        className="w-6 h-6 cursor-pointer"
                                        onClick={() => updateQuestion(question.id)}
                                    />
                                }
                            />
                        </div>
                        <p className="text-sm text-neutral-400">{t('feedback-change-question')}</p>
                    </div>
                </div>
            </CardFooter>}
        </Card>
    );
};

export default QuestionCard;
