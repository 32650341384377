// Use variable below if you want to test against local endpoint
// let api_endpoint = "http://localhost:8070/api/"
let api_endpoint = "https://dazzle-backend.dazzle.zebrain.se/api/"
let web_socket_endpoint = "ws://localhost:9003"
let file_host = "http://localhost/"
let app_host = "http://localhost:1234/"

if (window.location.hostname === "sebra-staging.pages.dev") {
  api_endpoint = "https://dazzle-backend.dazzle.zebrain.se/api/"
  web_socket_endpoint = "https://stage.zebrain.se/wss"
  file_host = "https://sebra-staging.pages.dev/"
  app_host = "https://sebra-staging.pages.dev/"
}

export interface AppConfig {
  API_ENDPOINT: string
  WEB_SOCKET_ENDPOINT: string
  FILE_HOST: string
  APP_HOST: string
}

export const CONFIG: AppConfig = {
  API_ENDPOINT: api_endpoint,
  WEB_SOCKET_ENDPOINT: web_socket_endpoint,
  FILE_HOST: file_host,
  APP_HOST: app_host
}