import React, {useEffect, useRef, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

import '../../lang/i18n';
import {useTranslation} from 'react-i18next';

import {useData as uiUseData} from '../../contexts/UIContext';

import DevelopmentCard from './components/DevelopmentCard';

import taskIcon from 'shared-assets/icons/task.svg';
import learningIcon from 'shared-assets/icons/learning.svg';

import {DevelopmentAreaInterface} from "../../interfaces/common";


export default function DevelopmentAreaPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [loaded, setLoaded] = useState(false);
  const [developmentAreas, setDevelopmentAreas] = useState<DevelopmentAreaInterface[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navbarHeight } = uiUseData();

  // check if mobile
  const isMobile = window.innerWidth < 636;

  // const getData = async () => {
  //   const token = await getAccessTokenSilently();
  //   const response = await fetch(
  //     'http://localhost:8070/api/client/development-plan',
  //     {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  //
  //   const results = await response.json()
  //
  //   console.log(results)
  // }

  const getOverview = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      'https://feedback-api.dazzle.zebrain.se/focus_area_overview',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const setSortOrder = (area: DevelopmentAreaInterface) => {
      // Calculate sort order
      if (area.feedback_progresses.length === 0) {
        // No progresses = not active => sort last
        return 3
      }

      // TODO: Check assumption that last progress is first in array
      const progress = area.feedback_progresses[area.feedback_progresses.length - 1]
      if (!progress.has_self_assessment) {
        return 1;
      }
      // If we have enough answers or deadline is passed, area is activated => return 2
      if (progress.num_answers >= progress.total_feedback_users ||
        new Date(progress.deadline) < new Date()) {
        return 2
      } else {
        // Started, but not done = in activation => return 1
        return 1
      }
    }

    const results = await response.json() as Array<DevelopmentAreaInterface>;
    // Uncomment below for mock data
    // results[0].feedback_progresses = [
		// 	{
		// 		"questionnaire_id": "DpBeoJia",
		// 		"client_id": "martin",
		// 		"created": "2024-12-05T17:05:57.735Z",
		// 		"deadline": "2024-12-18T14:24:47.605Z",
    //    "last_feedback": "2024-12-24T14:24:47.605Z",
		// 		"has_self_assessment": true,
		// 		"num_answers": 3,
		// 		"total_feedback_users": 3,
		// 		"feedback_results": [
		// 			{
		// 				"question_index": 0,
		// 				"answer_value": 4,
		// 				"num_answers": 3
		// 			},
		// 			{
		// 				"question_index": 1,
		// 				"answer_value": 2,
		// 				"num_answers": 3
		// 			}
		// 		],
		// 		"self_assessment_answer": [
		// 			{
		// 				"type": "AgreementScale",
		// 				"value": 4
		// 			},
		// 			{
		// 				"type": "AgreementScale",
		// 				"value": 3
		// 			}
		// 		]
		// 	}
		// ]

    for (const result of results) {
      result.sortOrder = setSortOrder(result)
    }
    setDevelopmentAreas(results)
    setLoaded(true)
  }

  useEffect(() => {
    // fetchDevelopmentAreas()
    getOverview().then(() => console.log('Got results from getOverview()'))
    // getData();
  }, []);

  if (!developmentAreas || !developmentAreas.length) {
    return (
      <div className="mt-6">
        <div className="flex justify-center">
          <img src={taskIcon} alt="task icon" className="w-6 h-6 desktop:w-10 desktop:h-10" />
        </div>
        <p className="text-center">
          {loaded ? t('no-development-areas') : t('loading-data')}
        </p>
      </div>
    )
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white my-4 desktop:my-6">
        {t("development-plan-nav-title")}
      </h1>
      <div>
        <div className="flex flex-col mb-2">
          <h2 className="text-lg font-medium dark:text-white">
            {t('development-area-page-title')}
          </h2>
        </div>
        <div
          className="gap-2 flex flex-col"
          style={{
            paddingBottom: `${isMobile ? navbarHeight + 24 : 0}px`,
          }}
        >
        {
          developmentAreas.sort((a, b) => (
            (a.sortOrder === undefined ? 4 : a.sortOrder) - (b.sortOrder === undefined ? 4 : b.sortOrder)
          ))
            .map((area: any, index: number) => {
            return (
              <DevelopmentCard
                key={`dev-area-card-${index}`}
                leftIcon={learningIcon}
                developmentArea={area}
                onClick={() => navigate(`/development-plan/focus-area/${area.id}`)}
              />
            )
          })
        }
        </div>
      </div>
    </>
  )
}