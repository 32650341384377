import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface RecipientInfoModalProps {
    isOpen: boolean;
    feedbackType: string;
    onClose: () => void;
}

const RecipientInfoModal = ({ isOpen, onClose, feedbackType }: RecipientInfoModalProps) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';
    const isTask = feedbackType === 'task'

    const textPart1 = isSwedish ? 
    `Här kan du välja vem som ska ge feedback till dig.` :
    `Here, you can select who will provide feedback for you. `;

    let textPart2: string
    if (isTask) {
        if (isSwedish) {
            textPart2 = 'Välj kollegor som kan utvärdera dina färdigheter inom den valda uppgiften och sikta på en balanserad grupp för att säkerställa en väl avrundad feedback.'
        } else {
            textPart2 = 'Choose colleagues who are able to evaluate your performance in connection with the task and aim for a balanced group to ensure well-rounded feedback.'
        }
    } else {
        if (isSwedish) {
            textPart2 = 'Välj kollegor som kan utvärdera dina färdigheter inom det valda fokusområdet och sikta på en balanserad grupp för att säkerställa en väl avrundad feedback.'
        } else {
            textPart2 = 'Choose colleagues who are able to evaluate your skills in the chosen Focus Area and aim for a balanced group to ensure well-rounded feedback.'
        }
    }

    const textPart3 = isSwedish ? 
    `Tänk på att vi kommer att acceptera inlämningar i 7 dagar, så försök att se till att de valda mottagarna är tillgängliga.` :
    `Please keep in mind that we’ll be accepting submissions for 7 days, so try to make sure the selected recipients are available.`

    const textPart4 = isSwedish ? 
    `När du är klar, klicka på Nästa.` :
    `When ready, click Next.`

    
    
    
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('select-recipients')}</h3>
            </ModalHeader>
            <ModalBody>
                <>
                    <p className="text-normal text-base">{textPart1}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart2}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart3}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart4}</p>
                </>
            </ModalBody>
        </Modal>
    );
};

export default RecipientInfoModal;