// AppRouter.js

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {CONFIG} from "./constants";
import ChatView from "./pages/ChatView";
import TasksPage from "./pages/TasksPage";
import DevelopmentAreaPage from "./pages/DevelopmentAreaPage";
import FocusAreaPage from "./pages/FocusAreaPage";
import { App } from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import AcceptInvite from "./pages/AcceptInvitePage";
import SupportPage from "./pages/SupportPage";
import AboutPage from "./pages/AboutPage";
import CalendarPage from "./pages/CalendarPage";
import FeedbackPage from "./pages/FeedbackPage";
import CreateFeedbackPage from "./pages/CreateFeedbackPage";
import FeedbackResultPage from "./pages/FeedbackResultPage";
import FeedbackRespondentPage from "./pages/FeedbackRespondentPage";
import TokenPage from "./pages/TokenPage";

const AppRouter = ({ outerRoute }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const [enoughPrivileges, setEnoughPrivileges] = useState(false)

  // Define routes without handle properties
  const routes = [
    {
      path: '/',
      element: <ChatView />,
    },
    {
      path: '/tasks',
      element: <TasksPage />,
    },
    {
      path: '/feedback/create',
      element: <CreateFeedbackPage />,
    },
    {
      path: '/accept_invite/:code',
      element: <AcceptInvite />,
    },
    {
      path: '/development-plan',
      element: <DevelopmentAreaPage />,
    },
    {
      path: '/development-plan/focus-area/:id',
      element: <FocusAreaPage />,
    },
    {
      path: '/feedback/questionnaire/:id',
      element: <FeedbackRespondentPage />,
    },
    {
      path: '/feedback',
      element: <FeedbackPage />,
    },
    {
      path: '/feedback/result/:feedback_type/:id',
      element: <FeedbackResultPage />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/support',
      element: <SupportPage />,
    },
    {
      path: '/calendar',
      element: <CalendarPage />,
    },
    {
      path: '/token',
      element: <TokenPage />,
    }
    // Add more routes as needed
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: routes,
    },
  ]);

  useEffect(() => {
    if (outerRoute) {
      router.navigate(outerRoute);
    }
  }, [outerRoute]);

  useEffect(() => {
    if (window.location.pathname.startsWith('/feedback/questionnaire/')) {
      // When there is no need for a logged in session, return
      setEnoughPrivileges(true)
      return
    }
    if (!isLoading) {
      if (!isAuthenticated) {
        const loginOptions = {
          appState: {
            returnTo: window.location.pathname,
          },
        };

        if (window.location.pathname.startsWith('/accept_invite/')) {
          loginOptions.authorizationParams = {screen_hint: "signup"};
        }

        loginWithRedirect(loginOptions);
      } else {
        getAccessTokenSilently().then(auth0token => {
          console.log("getAccessTokenSilently at boot succeeded");
          window.localStorage[CONFIG.TOKEN_NAME] = auth0token
          setEnoughPrivileges(true)
        }).catch(reason => {
          // We might get caught in a loop here if third party cookies are disabled (incognito):
          // https://community.auth0.com/t/auth0-react-getaccesstokensilently-and-incognito-mode-how-to-fix-it/84310
          console.error(reason)
          // alert('Fatal error! Cannot get access token. If you are in incognito mode, this service will not work.\n' +
          //   'If not in incognito mode, please contact tech@zebrain.se.')
        });
      }
    }
  }, [isLoading, isAuthenticated]);

  if (!enoughPrivileges) {
    return (<div className="mt-4 text-center text-neutral-700">Loading...</div>)
  }

  return <RouterProvider router={router} />;
};

export default AppRouter;
