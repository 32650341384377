import React from 'react';
import {useTranslation} from 'react-i18next';
import chevronDown from "shared-assets/icons/chevronDown.svg";
import blueChevronDown from "shared-assets/icons/primary-500/chevron-down.svg";
import { CustomButton, TextLink } from 'shared-components';

interface Props {
  linkAbove: boolean;
  initialHeight: string;
  maxHeight?: string;
  children: React.ReactNode;
}

const ChevronDownIcon = ({ isOpen }: { isOpen: boolean }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="currentColor"  // ensure Tailwind color classes apply
    xmlns="http://www.w3.org/2000/svg"
    className={`
      transform transition-transform duration-300
      text-white          // White by default
      group-hover:text-primary-400  // Change color on entire button hover
      w-4 h-4             // Adjust if needed
      ${isOpen ? 'rotate-180' : ''}
    `}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.79289 8.79289C5.18342 8.40237 5.81658 8.40237 6.20711 8.79289L12.5 15.0858L18.7929 8.79289C19.1834 8.40237 19.8166 8.40237 20.2071 8.79289C20.5976 9.18342 20.5976 9.81658 20.2071 10.2071L13.2071 17.2071C12.8166 17.5976 12.1834 17.5976 11.7929 17.2071L4.79289 10.2071C4.40237 9.81658 4.40237 9.18342 4.79289 8.79289Z"
    />
  </svg>
);

const ReadMore: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(props.initialHeight);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const toggleOpen = () => {
    if (open) {
      setMaxHeight(props.initialHeight);
    } else {
      if (props.maxHeight) {
        setMaxHeight(props.maxHeight);
      } else {
        let height = 100
        if (contentRef && contentRef.current) {
          height = contentRef.current.scrollHeight
        }
        setMaxHeight(height + 'px')
      }

    }
    setOpen(!open);
  }

  const showToggle = () => {
    return (
      <TextLink
        onClick={toggleOpen}
        color="whitePrimaryMix"
        size="sm"
        // Provide the inline SVG with flipping logic
        rightIcon={<ChevronDownIcon isOpen={open} />}
        text={open ? 'Read Less' : 'Read More'}
      />
    )
  }

  return (
    <>
      {props.linkAbove && showToggle()}

      <div style={{maxHeight: maxHeight}}
           className={"overflow-hidden transition-all duration-300" + (open ? "" : " relative after:content-[''] after:absolute after:inset-x-0 after:bottom-0 after:h-8 after:bg-gradient-to-b after:from-transparent after:to-neutral-900")}
           ref={contentRef}>
        {props.children}
      </div>

      {!props.linkAbove && showToggle()}
    </>
  )
}

export default ReadMore;