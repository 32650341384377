import React, { useEffect, useState } from 'react';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

import chevronIcon from 'shared-assets/icons/chevronRight.svg';
import taskIcon from 'shared-assets/icons/task.svg';
import insightIcon from 'shared-assets/icons/insights.svg';
import learningIcon from 'shared-assets/icons/learning.svg';
import taskIconPrimary from 'shared-assets/icons/primary/task.svg';
import learningIconPrimary from 'shared-assets/icons/primary/learning.svg';
import {CustomButton} from 'shared-components'

import { useNavigate } from 'react-router-dom';
import TabMenu from "../../components/TabMenu/TabMenu";
import Api from "../../services/Api";

interface FeedbackAndInsightInterface {
    originId: string;
    type: string;
    title: string;
}

const FeedbackPage = () => {
    const api = Api.getInstance()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [tasks, setTasks] = useState<FeedbackAndInsightInterface[]>([]);
    const [focusAreas, setFocusAreas] = useState<FeedbackAndInsightInterface[]>([]);

    const getData = () => {
        api.get('client/feedback-and-insights').then((response) => {
            console.log(response.json)
            setTasks(response.json.insights.tasks)
            setFocusAreas(response.json.insights.focusAreas)
            setLoaded(true);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const showContent = () => {
      if (!loaded) {
        return <></>
      }
      let insights: Array<FeedbackAndInsightInterface> = []
      if (tab === 0) {
        if (focusAreas.length === 0) {
          return (
            <div className="mt-14 text-center">
              <img src={insightIcon} alt="insights" className="h-8 mx-auto"/>
              <p className="mt-4">{t("no-feedback-yet")}?</p>
              <p className="mt-8">
                {t("send-request-from-focus-area")}!
              </p>
              <p>
                {t("feedback-will-appear-focus-area")}
              </p>
              <CustomButton className="mt-8" onClick={() => navigate('/development-plan')}>
                {t("go-to-focus-area")}
              </CustomButton>
            </div>
          )
        }
        insights = focusAreas
      } else if (tab === 1) {
        if (tasks.length === 0) {
          return (
            <div className="mt-14 text-center">
              <img src={insightIcon} alt="insights" className="h-8 mx-auto"/>
              <p className="mt-4">{t("no-feedback-yet")}?</p>
              <p className="mt-8">
                {t("send-request-from-task")}!
              </p>
              <p>
                {t("feedback-will-appear-task")}
              </p>
              <CustomButton className="mt-8" onClick={() => navigate('/tasks')}>
                {t("go-to-tasks")}
              </CustomButton>
            </div>
          )
        }
        insights = tasks
      }
      return (
        <>
          {insights.map((result, index) => (
              <div key={"feedback_" + index}
                   onClick={() => navigate('/feedback/result/' + result.type + '/' + result.originId)}
                   className="flex justify-between mt-2 p-2 bg-neutral-800 rounded-md cursor-pointer hover:bg-neutral-700">
                <div className="font-medium">{result.title}</div>
                <img src={chevronIcon} alt="next" />
              </div>
            ))}
        </>
      )
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 mt-6">
            <h1 className="text-2xl font-medium text-white">
                {t("feedback-title")}
            </h1>

            <div className="my-6">
                <TabMenu items={[
                {
                  title: t("focus-areas"),
                  iconUrl:tab === 0 ? learningIconPrimary : learningIcon,
                  callback: () => setTab(0)
                },
                {
                  title: t("tasks"),
                  iconUrl:tab === 1 ? taskIconPrimary : taskIcon,
                  callback: () => setTab(1)
                },
                ]}/>
            </div>
            {showContent()}
        </div>
    );
};

export default FeedbackPage;
