// CreateQuestions.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { CustomButton, TextLink } from 'shared-components';

import { questionnaireAtom } from '../../../../jotai_state';
import { useData } from '../../../../contexts/dataContext';
import QuestionCard from '../QuestionCard';
import ShowQuestionsInfoModal from '../ShowQuestionsInfoModal';
import LeaveModal from '../LeaveModal';
import DeleteQuestionModal from '../DeleteQuestionModal';
import MaskedIcon  from '../../../../components/MaskedIcon';
import plusIcon from 'shared-assets/icons/plus.svg';
import alertIcon from 'shared-assets/icons/red/alert.svg';
import infoIcon from 'shared-assets/icons/white/information-circle.svg';


interface CreateQuestionsProps {
    goToNextStep: () => void;
}

const CreateQuestions: React.FC<CreateQuestionsProps> = ({ goToNextStep }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { client_id } = useData();
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const feedbackSourceId = queryParams.get('id');
    const feedbackSourceContent = queryParams.get('content');
    const feedbackSourceTitle = queryParams.get('title');
    const feedbackType = queryParams.get('type');
    const [hasRemovedQuestion, setHasRemovedQuestion] = useState(false);
    const pageName = feedbackType === "task" ? t('menu-my-tasks') : t('focus-area');

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [inputValueMap, setInputValueMap] = useState<Record<number, string>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [showNumQuestionsAlert, setShowNumQuestionsAlert] = useState(false);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [questionIdToDelete, setQuestionIdToDelete] = useState<number | null>(null);

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const answerOptions = [
        t('no-answer'),
        t('strongly-disagree'),
        t('disagree'),
        t('neutral'),
        t('agree'),
        t('strongly-agree'),
    ];

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            const hasParams = !!feedbackType && !!feedbackSourceId;
            const isEmpty = questionnaire.structure.length === 0;
            const isSameOrigin =
                feedbackType === questionnaire.origin.type &&
                feedbackSourceContent === questionnaire.origin.content;
            const shouldCleanState = questionnaire.cleanState;
            const differentIds = questionnaire.id !== feedbackSourceId

            if (isEmpty && hasRemovedQuestion) {
                // When last question is removed, just generate one new question
                generateOneNewQuestion()
            } else if (hasParams && (isEmpty || !isSameOrigin || shouldCleanState || differentIds)) {
                const origin = {
                    id: feedbackSourceId,
                    type: feedbackType,
                    content: feedbackSourceContent,
                    title: feedbackSourceTitle,
                };
                generateInitialQuestionnaire(origin);
            } else if (isEmpty && !!questionnaire.origin.type) {
                // If the questionnaire is empty, generate new questions
                regenerateQuestions(questionnaire.origin, questionnaire.recipients);
            } else if ((!feedbackType || !feedbackSourceId) && questionnaire.structure.length === 0) {
                backToPreviousPage()
            }
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, questionnaire.structure, location]);

    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }

        if(questionnaire.structure.length > 0 && questionnaire.structure[questionnaire.structure.length - 1].id === -1) {
            fetchOneNewQuestion();
        }
    }, [questionnaire.structure]);

    const createNewQuestionBattery = async (origin) => {
        let token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/generate_questionnaire',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        client_id: client_id,
                        origin: origin,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error('Failed to create questionnaire');
            }

            const questionData = await response.json();
            console.log('Question data:', questionData);
            return questionData;
        } catch (error) {
            // Clear the questionnaire if an error occurs
            console.error('Failed to create questionnaire', error);
        }
    };

    const generateInitialQuestionnaire = async (origin) => {

        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: '',
            type: '',
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire({
            recipients: [],
            structure: [placeholderQuestion],
            origin: origin,
            id: feedbackSourceId,
            cleanState: false,
        });

        try {
            const newQuestionsList = await createNewQuestionBattery(origin);

            setQuestionnaire((prev) => ({
                ...prev,
                origin: origin,
                structure: newQuestionsList.map((question, index) => ({
                    ...question,
                    isLoading: false,
                    id: index,
                })),
                recipients: [],
            }));
        } catch (error) {
            alert("Something went wrong. Please try again.");
            backToPreviousPage();
        }
    };

    const regenerateQuestions = async (origin, recipients) => {
        console.log('Generating new question');

        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: '',
            type: '',
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire({
            recipients: questionnaire.recipients || [],
            structure: [placeholderQuestion],
            origin: origin,
            id: feedbackSourceId,
            cleanState: false,
        });

        try {
            const newQuestionsList = await createNewQuestionBattery(origin);

            setQuestionnaire((prev) => ({
                ...prev,
                origin: origin,
                structure: newQuestionsList.map((question, index) => ({
                    ...question,
                    isLoading: false,
                    id: index,
                })),
                recipients: recipients || [],
            }));
        } catch (error) {
            // Show alert
            alert("Something went wrong. Please try again.");
            backToPreviousPage();
        }
    };

    const fetchOneNewQuestion = async () => { 
        let token = await getAccessTokenSilently();
        try {
            const structure = questionnaire.structure.map((question) => {
                return {
                    type: 'AgreementScale',
                    description: question.description,
                };
            });
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/add_question',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        origin: {
                            id: feedbackSourceId,
                            type: feedbackType,
                            content: feedbackSourceContent,
                            title: feedbackSourceTitle,
                        },
                        structure: structure,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionData = await response.json();
            console.log('Question data:', questionData);
            questionData.id = questionnaire.structure.length;
            questionData.isLoading = false;

            let newStructure = [...questionnaire.structure];
            // Replace the placeholder with the new question
            newStructure[newStructure.length - 1] = questionData;

            console.log('New structure:', newStructure);

            // Update the questionnaire state
            setQuestionnaire((prev) => ({
                ...prev,
                structure: newStructure,
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }

    const generateOneNewQuestion = async () => {
        // Create a placeholder question with isLoading: true
        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: '',
            type: '',
        };

        // Add the placeholder question to the questionnaire
        setQuestionnaire((prev) => ({
            ...prev,
            structure: [...prev.structure, placeholderQuestion],
        }));
    };

    const updateQuestion = async (id) => {
        const value = inputValueMap[id];
        console.log(`Updating question with id ${id} with value: ${value}`);
        const question = questionnaire.structure.find((question) => question.id === id);
        const structure = questionnaire.structure.map((question) => {
            return {
                type: 'AgreementScale',
                description: question.description,
            };
        });

        const origin = {
            id: feedbackSourceId,
            type: feedbackType,
            content: feedbackSourceContent,
            title: feedbackSourceTitle,
        };

        // Set the question with the given id to loading
        setQuestionnaire((prev) => ({
            ...prev,
            structure: prev.structure.map((question) =>
                question.id === id ? { ...question, isLoading: true } : question
            ),
        }));

        let token = await getAccessTokenSilently();

        try {
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/regenerate_question',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        origin: origin,
                        structure: structure,
                        question_to_regenerate: question.description,
                        prompt: value,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const questionData = await response.json();

            const updatedQuestion = {
                ...question,
                description: questionData.description,
                isLoading: false,
            };

            console.log('Question data:', questionData);
            console.log('Updated question:', updatedQuestion);

            // Update the questionnaire state by replacing the old question with the updated one
            setQuestionnaire((prev) => ({
                ...prev,
                structure: prev.structure.map((q) => (q.id === id ? updatedQuestion : q)),
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    };

    const removeQuestion = () => {
        const id = questionIdToDelete;
        console.log(`Removing question with id ${id}`);
        setQuestionnaire({
            ...questionnaire,
            structure: questionnaire.structure.filter((question) => question.id !== id),
        });
        setQuestionIdToDelete(null);
        // This is used to avoid generating new questions set after deleting last question
        setHasRemovedQuestion(true);
        setShowDeleteModal(false);
    };

    const LoadingAnimation = () => {
        return (
            <div className="flex items-center justify-center">
                <div className="relative">
                    {/* Circle Container */}
                    <div className="w-24 h-24 rounded-full flex items-center justify-center bg-neutral-700">
                        {/* Dots */}
                        <div className="flex space-x-2">
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0s]"></div>
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.2s]"></div>
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.4s]"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const checkRules = () => {
        const numQuestions = questionnaire.structure.length;
        return numQuestions >= 1;
    }

    const handleNext = () => {
        // Custom logic before moving to the next step
        setShowNumQuestionsAlert(false);
        if(!checkRules()) {
            setShowNumQuestionsAlert(true);
        } else if (questionnaire.structure.length > 0) {
            const isLoading = questionnaire.structure.some((question) => question.isLoading);
            if (!isLoading) {
                goToNextStep();
            } else {
                // Optionally, display a message that questions are still loading
                console.log('Questions are still loading');
            }
        } else {
            // Optionally, display a message that there are no questions
            console.log('No questions have been created');
        }
    };

    const backToPreviousPage = () => {
        navigate(feedbackType === "task" ? '/tasks' : '/development-plan/focus-area/' + feedbackSourceId);
    }

    if (!questionnaire || isLoading) {
        return null;
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0">
            <div className="flex flex-row items-center gap-1 mb-6">
                <h1 className="text-xl font-medium dark:text-white">
                    {t('feedback-create-review-questions')}
                </h1>
                <CustomButton
                    buttonType="transparentBase"
                    onClick={() => setShowInfoModal(true)}
                    size="md"
                    isRound={true}
                    leftIcon={<img src={infoIcon} alt="Info" className="w-6 h-6" />}
                >
                </CustomButton>
            </div>

            {/* Question cards */}
            <div ref={scrollableDivRef} className="flex flex-col gap-2">
                {/* Question cards */}
                {questionnaire.structure.length === 0 && (
                    <div className="flex flex-col gap-4 bg-neutral-800 rounded-md p-4">
                        <div className="flex justify-center">
                            <LoadingAnimation />
                        </div>
                    </div>
                )}
                {questionnaire.structure.map((question, index) => {
                    if (question.isLoading) {
                        return (
                            <React.Fragment key={String(question.id)}>
                                <div className="flex flex-col bg-neutral-800 rounded-md p-4">
                                    <div className="flex justify-center">
                                        <LoadingAnimation />
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    return (
                        <div key={`create-question-card-${question.id}`}>
                            <QuestionCard
                                question={question}
                                removeQuestion={() => {
                                    setQuestionIdToDelete(question.id)
                                    setShowDeleteModal(true);
                                }}
                                answerOptions={answerOptions}
                                updateQuestion={updateQuestion}
                                setInputValueMap={setInputValueMap}
                                index={index}
                                t={t}
                            />
                        </div>
                    );
                })}
            </div>

            {/* Buttons */}
            <div className="flex flex-col">
                {showNumQuestionsAlert && (
                    <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2  mt-2">
                        <img src={alertIcon} alt="Alert" className="w-6 h-6" />
                        <span>{t('create-questions-alert')}</span>
                    </div>
                )}
                <div className="mb-6 mt-2">
                    {/* Add Question Button */}
                    <TextLink
                        color="white"
                        onClick={generateOneNewQuestion}
                        className="w-full"
                        leftIcon={<MaskedIcon 
                            src={plusIcon} 
                            className="h-6 w-6"
                        />}
                        size="base"
                    >
                        {t('feedback-add-question')}
                    </TextLink>
                </div>

                <div className="flex flex-col gap-4">
                    {/* Next Button */}
                    <CustomButton
                        onClick={() => handleNext()}
                        className="w-full"
                    >
                        {t('next')}
                    </CustomButton>


                    {/* Add Question Button */}
                    <TextLink
                        color="white"
                        onClick={() => setShowLeaveModal(true)}
                        className="w-full"
                        size="sm"
                    >
                        {t('back-to', { page: pageName })}
                    </TextLink>
                </div>
            </div>

            <ShowQuestionsInfoModal
                isOpen={showInfoModal}
                feedbackType={feedbackType ? feedbackType : "task"}
                onClose={() => setShowInfoModal(false)}
            />

            <LeaveModal
                isOpen={showLeaveModal}
                onClose={() => setShowLeaveModal(false)}
                onLeavePage={() => backToPreviousPage()}
            />

            <DeleteQuestionModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={removeQuestion}
                questions={questionnaire.structure.length}
            />
        </div>
    );
};

export default CreateQuestions;