import React, { useState, useRef, useEffect } from 'react';
import checkIcon from 'shared-assets/icons/neutral-800/check.svg';

export interface Step {
  title: string;
  description?: string;
  content: React.ReactNode;
}

export interface StepperProps {
  steps: Step[];
  currentIndex: number;
  completedSteps?: number[];
  direction?: 'horizontal' | 'vertical';
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  currentIndex,
  completedSteps = [],
  direction = 'horizontal',
}) => {

  if (!steps || steps.length === 0) {
    return null;
  }
  const [lineHeights, setLineHeights] = useState<number[]>(Array(steps.length).fill(40));
  const circleRef = useRef<Array<HTMLDivElement>>(Array(steps.length));

  const circleSize = 28; // size-7 is approximately 28px
  const circleCenterOffset = circleSize / 2; // 14px

  useEffect(() => {
    // When we have one circle ref per step, calculate line height between them.
    if (circleRef.current.length === steps.length) {
      calculateLineHeights()
    }
  }, [circleRef.current])

  const getCircleClasses = (isActive: boolean, isCompleted: boolean) => {
    let classes =
      'size-7 flex justify-center items-center font-medium rounded-full z-10 ';

    if (isActive) {
      classes += 'bg-success-300 text-neutral-900';
    } else if (isCompleted) {
      classes += 'bg-success-500 text-neutral-900';
    } else {
      classes += 'bg-neutral-600 text-neutral-400';
    }

    return classes;
  };

  const getStepClasses = (isActive: boolean, isCompleted: boolean, vertical: boolean) => {
    let classes = 'relative flex ';
    classes += vertical ? 'flex-row' : 'flex-col';

    if (vertical) {
      // In vertical mode, we align items to the start (left side)
      classes += ' justify-start items-start';
    } else {
      // Horizontal mode
      classes += ' justify-start flex-1 items-center';
    }

    if (isActive) {
      classes += ' active';
    } else if (isCompleted) {
      classes += ' success';
    }

    return classes;
  };

  const isLineCompleted = (index: number) => completedSteps.includes(index);

  const getLineClasses = (completed: boolean) => {
    return completed ? 'bg-success-500' : 'bg-neutral-700';
  };

  const calculateLineHeights = () => {
    // This is probably unnecessary complex. To calculate line height between step circles we pull position from
    // each circle and calculate the distance between them. Subtract circle height.
    let changed = false
    const newLineHeights = [...lineHeights]
    for (let index = 1; index < steps.length; index++) {
      const prevCircle = document.getElementById("stepper_circle_" + (index - 1))
      const thisCircle = document.getElementById("stepper_circle_" + index)
      if (prevCircle && thisCircle) {
        const prevRect = prevCircle.getBoundingClientRect();
        const thisRect = thisCircle.getBoundingClientRect();
        const height = thisRect.top - prevRect.top - circleSize
        if (height > 40) {
          newLineHeights[index] = height
          changed = true
        }
      }
    }
    if (changed) {
      setLineHeights(newLineHeights)
    }

  }

  const addCircleRef = (el: HTMLDivElement|null, index: number) => {
    if (el) {
      // Add a ref for this circle div element
      circleRef.current[index] = el
    }
  }

  // HORIZONTAL MODE
  if (direction === 'horizontal') {
    return (
      <div className="w-full">
        <ul className="relative flex items-center justify-between w-full">
          {steps.map((step, index) => {
            const isActive = index === currentIndex;
            const isCompleted = completedSteps.includes(index) && !isActive;
            const titleClasses =
              'text-sm font-medium mt-2 ' +
              (isActive || isCompleted ? 'text-white' : 'text-white');
            const descriptionClasses =
              'text-sm font-normal mt-2 ' +
              (isActive || isCompleted ? 'text-neutral-400' : 'text-neutral-400');

            const leftLineCompleted = index > 0 ? isLineCompleted(index - 1) : false;
            const rightLineCompleted =
              index < steps.length - 1 ? isLineCompleted(index) : false;

            return (
              <li key={index} className={getStepClasses(isActive, isCompleted, false)}>
                <div className="relative flex items-center justify-center w-full h-7">
                  {index > 0 && (
                    <div
                      className={`absolute left-0 top-1/2 -translate-y-1/2 h-px ${getLineClasses(
                        leftLineCompleted
                      )}`}
                      style={{ width: '50%' }}
                    ></div>
                  )}

                  <span className={getCircleClasses(isActive, isCompleted)}>
                    {isCompleted ? (
                      <img src={checkIcon} alt="Checkmark" className="size-4" />
                    ) : (
                      <span>{index + 1}</span>
                    )}
                  </span>

                  {index < steps.length - 1 && (
                    <div
                      className={`absolute right-0 top-1/2 -translate-y-1/2 h-px ${getLineClasses(
                        rightLineCompleted
                      )}`}
                      style={{ width: '50%' }}
                    ></div>
                  )}
                </div>


                <span>
                  <div className={titleClasses}>{step.title}</div>
                  {step.description && <div className={descriptionClasses}>{step.description}</div>}
                </span>
              </li>
            );
          })}
        </ul>

        <div className="mt-6 sm:mt-8">
          {steps.map((step, index) => {
            const isActive = index === currentIndex;
            return (
              <div key={index} style={{ display: isActive ? 'block' : 'none' }}>
                {step.content}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // VERTICAL MODE
  return (
    <div className="w-full flex flex-col">
      <ul className="relative flex flex-col items-start w-full h-full">
        {steps.map((step, index) => {
          const isActive = index === currentIndex;
          const isCompleted = completedSteps.includes(index) && !isActive;
          const titleClasses =
            'text-sm font-medium ml-2 ' +
            (isActive || isCompleted ? 'text-white' : 'text-white');
            const descriptionClasses =
              'text-sm font-normal ml-2 ' +
              (isActive || isCompleted ? 'text-neutral-400' : 'text-neutral-400');

          const prevStepCompleted = index > 0 ? completedSteps.includes(index - 1) : false;

          return (
            <li
              key={index}
              className={`${getStepClasses(isActive, isCompleted, true)} relative grow w-full`}>

              {/* Circle and title container */}
              <div className="relative flex items-center" style={{ marginTop: circleCenterOffset }}>
                {/* Complex solution to calculate line height. We need refs to find positions of circles */}
                <div className={"relative " + getCircleClasses(isActive, isCompleted)}
                     ref={el => addCircleRef(el, index)}
                     id={"stepper_circle_" + index}>
                  {index > 0 && (
                    <div
                      className={'absolute left-[14px] ' + (prevStepCompleted ? 'bg-success-500' : 'bg-neutral-600')}
                      style={{ bottom: circleSize, width: 1, height: lineHeights[index] }}
                    ></div>
                  )}
                  {isCompleted ? (
                    <img src={checkIcon} alt="Checkmark" className="size-4" />
                  ) : (
                    <span style={{width: 28, textAlign: "center"}}>{index + 1}</span>
                  )}
                </div>
                <span>
                  <div className={titleClasses}>{step.title}</div>
                  {step.description && <div className={descriptionClasses}>{step.description}</div>}
                </span>
              </div>
            </li>
          );
        })}
      </ul>

      {/* HD: commented out this section - do we ever display content in vertical stepper? */}
      {/*<div className="mt-5 sm:mt-8 w-full flex-1 overflow-auto">*/}
      {/*  {steps.map((step, index) => {*/}
      {/*    const isActive = index === currentIndex;*/}
      {/*    return (*/}
      {/*      <div key={index} style={{ display: isActive ? 'block' : 'none', height: '100%' }}>*/}
      {/*        {step.content}*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};

export default Stepper;
