
import React from 'react';
import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../../../jotai_state';
import { useAuth0 } from '@auth0/auth0-react';

import alertIcon from 'shared-assets/icons/red/alert.svg';
import chevronLeftIcon from 'shared-assets/icons/chevronLeft.svg';
import pencilIcon from 'shared-assets/icons/white/pencil.svg';

import { CustomButton, Badge, TextLink } from 'shared-components';
import QuestionCard from '../QuestionCard';
import MaskedIcon  from '../../../../components/MaskedIcon';

interface SummaryProps {
    goToPreviousStep: () => void;
    goToStep: (index: number) => void;
}

const Summary = ({ goToPreviousStep, goToStep }: SummaryProps) => {
    const { getAccessTokenSilently, logout: auth0logout } = useAuth0();
    const translation = useTranslation();
    const { t } = translation;

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

    console.log(questionnaire)

    const answerOptions = [
        t('no-answer'),
        t("strongly-disagree"),
        t("disagree"),
        t("neutral"),
        t("agree"),
        t("strongly-agree"),
    ];
    const sendFeedbackRequest = async () => {
        // Go to next page if at least one question has been created and is not loading
        const body = JSON.stringify({
            origin: {
                id: questionnaire.origin.id,
                type: questionnaire.origin.type,
                content: questionnaire.origin.content,
                title: questionnaire.origin.title,
            },
            structure: questionnaire.structure,
            feedback_users: questionnaire.recipients.map((recipient) => recipient.email),
            deadline: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        })

        if (questionnaire.structure.length > 0 && questionnaire.recipients.length > 0) {
            try {
                let token = await getAccessTokenSilently();
                const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionnaire', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: body,
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const questionData = await response.json();

                setQuestionnaire((prev) => ({
                    ...prev,
                    cleanState: true,
                    hasSent: true,
                    remoteId: questionData.id,
                }));
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }

        }
    }

    

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6">
            <h1 className="text-xl font-medium dark:text-white">
                {t("feedback-create-summary")}
            </h1>

            <div className="flex flex-col gap-6">
                {/* Questions */}
                <div >
                    <div className="flex flex-row items-center gap-1 mb-2 items-center">
                        <h2 className="text-lg font-medium dark:text-white">
                            {t("feedback-review-questions")}
                        </h2>
                        <CustomButton
                            buttonType="transparentBase"
                            size="md"
                            isRound={true}
                            leftIcon={<img src={pencilIcon} alt="Info" className="w-4 h-4" />}
                            onClick={() => goToStep(0)}
                        >
                        </CustomButton>
                    </div>
                    <div className="flex flex-col pb-1 px-[1px]">
                        {questionnaire.structure.length > 0 ? (
                            questionnaire.structure.map((question, index) => {
                                return (
                                  <>
                                      {
                                          index > 0 &&
                                        <hr className="border-neutral-700 my-4"/>
                                      }
                                      <p className="font-medium">{t('question')} {index + 1}</p>

                                      {/* Question description */}
                                      <p className="text-neutral-400 mt-1">
                                          {question.description}
                                      </p>

                                      {/* Answer input */}
                                      <p className="text-sm font-medium mt-2">{t('answer-options')}</p>
                                      <div className="flex flex-col gap-1">
                                          {question.type === "AgreementScale" && answerOptions.toReversed().map((option, idx) => (
                                            <div className="flex" key={`question-${idx}`}>
                                                <ul
                                                  className="flex gap-2 list-disc list-inside text-neutral-400">
                                                    <li className="text-sm">{option}</li>
                                                </ul>
                                            </div>
                                          ))}
                                      </div>
                                  </>
                                // <div key={`summary-question-card-${question.id}`} className="flex flex-col gap-6">
                                //
                                //     <QuestionCard
                                //       question={question}
                                //       answerOptions={answerOptions}
                                //       showFooter={false}
                                //       removable={false}
                                //       index={index}
                                //       t={t}
                                //     />
                                // </div>
                            )
                                ;
                            })

                        ) : (
                          <div
                            className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
                              <img src={alertIcon} alt="Alert" className="w-6 h-6"/>
                              <span>{t('feedback-create-question-alert')}</span>
                          </div>
                        )}
                    </div>
                </div>

                {/* Recipients */}
                <div>

                    <div className="flex flex-row items-center gap-4 items-center pb-2">
                        <h2 className="text-lg font-medium dark:text-white">
                        {t("feedback-recipients")}
                        </h2>
                        <CustomButton
                            buttonType="transparentBase"
                            size="md"
                            isRound={true}
                            leftIcon={<img src={pencilIcon} alt="Info" className="w-4 h-4" />}
                            onClick={() => goToStep(1)}
                        >
                        </CustomButton>
                    </div>
                    {questionnaire.recipients.length > 0 ? (<div className="gap-3 flex flex-col pb-1 px-[1px]">
                        {questionnaire.recipients.map((recipient, index) => {
                            return (
                                <div key={"recipient_" + index}>
                                    <Badge
                                        label={recipient.name || recipient.email}
                                        round={true}
                                        color="bg-neutral-600"
                                    />
                                </div>
                            )
                        })}
                    </div>) : (
                        <div className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
                            <img src={alertIcon} alt="Alert" className="w-6 h-6" />
                            <span>{t('feedback-recipients-summary-alert')}</span>
                        </div>
                    )
                    }
                </div>
            </div>

            {/* Buttons */}
            <div className="flex flex-col gap-4">
                <CustomButton
                    onClick={() => sendFeedbackRequest()}
                    disabled={(questionnaire.structure.length === 0 || questionnaire.recipients.length < 1)}
                >
                    {t("send-request")}
                </CustomButton>

                <TextLink
                    color="white"
                    onClick={goToPreviousStep}
                    leftIcon={<MaskedIcon 
                        src={chevronLeftIcon} 
                        className="h-4 w-4"
                    />}
                    size="sm"
                >
                    Back
                </TextLink>

            </div>
        </div>
    );
}

export default Summary;