import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface ShowQuestionsInfoModalProps {
    isOpen: boolean;
    feedbackType: string;
    onClose: () => void;
}

const ShowQuestionsInfoModal = ({ isOpen, onClose, feedbackType }: ShowQuestionsInfoModalProps) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';

    let typeName: string;
    if (feedbackType === 'focus_area') {
        typeName = isSwedish ? 'fokusområdet' : 'Focus Area'
    } else {
        typeName = isSwedish ? 'uppgiften' : 'task'
    }

    const textPart1 = isSwedish ? 
    `Antalet frågor som Emma genererar beror på komplexiteten i ${typeName}.` :
    `The nr. of questions generated by Emma depends on the complexity of the ${typeName}.`;

    const textPart2 = isSwedish ? 
    `Om du vill redigera en fråga, skriv de önskade ändringarna i inmatningsfältet och Emma kommer att generera om den.` :
    `If you want to edit some question, type the requested changes in the input field and Emma will regenerate it.`

    const textPart3 = isSwedish ? 
    `Om du känner att någon aspekt inte har täckts, lägg till fler frågor.` :
    `If you feel like some aspect hasn’t been covered, add more questions.`

    const textPart4 = isSwedish ? 
    `Observera att frågorna du skapar kommer användas som bas för alla kommande feedback-förfrågningar för ${typeName}.` :
    `Please note the questions you create now will be used as a base for all future feedback requests for this ${typeName}.`

    const textPart5 = isSwedish ?
      `När du är klar, klicka på Nästa.` :
      `When done, click Next.`


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('create-questions')}</h3>
            </ModalHeader>
            <ModalBody>
                <>
                    <p className="text-normal text-base">{textPart1}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart2}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart3}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart4}</p>
                    <br/>
                    <p className="text-normal text-base">{textPart5}</p>
                </>
            </ModalBody>
        </Modal>
    );
};

export default ShowQuestionsInfoModal;