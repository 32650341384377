import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface LeaveModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLeavePage: () => void;
}

const LeaveModal = ({ isOpen, onClose, onLeavePage }: LeaveModalProps) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';

    const text = isSwedish ? 
    `Du lämnar flödet för att skicka feedback, dina ändringar kommer inte att sparas.` :
    `You’re leaving the feedback request flow, your changes won’t be saved.`;


    const onLeave = () => {
        onLeavePage();
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('leave-question')}</h3>
            </ModalHeader>
            <ModalBody>
                <p className="text-normal text-base">{text}</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onLeave} buttonType="outlineWhiteBase">{t('leave')}</Button>
                <Button onClick={onClose} buttonType="blueBase">{t('stay')}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default LeaveModal;