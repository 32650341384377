import {SelfAssessmentAnswerInterface, QuestionnaireAnswerInterface} from "../interfaces/common";

export function calculateScoreSelf (
  selfAssessmentAnswers: Array<SelfAssessmentAnswerInterface>, idx: number = -1) {
  let total = 0
  let nr = 0
  let i = 0
  for (const answer of selfAssessmentAnswers) {
    if ((idx === -1 || idx === i) && answer.value !== null) {
      total += answer.value + 1
      nr++
    }
    i++
  }
  if (nr > 0) {
    return total/nr
  }
  return 0
}

export function calculateScoreRespondents (
  feedbackResults: Array<QuestionnaireAnswerInterface>, idx: number = -1) {
  let total = 0
  let nr = 0
  for (const result of feedbackResults) {
    if ((idx === -1 || idx === result.question_index) && result.answer_value !== null) {
      total += (result.answer_value + 1) * result.num_answers
      nr += result.num_answers
    }
  }
  if (nr > 0) {
    return total/nr
  }
  return 0
}