import { useContext, useEffect, useState, useRef } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import './lang/i18n';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import { doc, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { ClientDataContextProvider } from './contexts/dataContext';
import {
  Breadcrumbs,
  Navbar
} from 'shared-components'

import * as Sentry from "@sentry/react";
import { useData } from './contexts/UIContext';
import { AuthContext } from './contexts/AuthContext';

import breadcrumbConfig from './helpers/breadcrumbConfig';

import chatBubbleIcon from 'shared-assets/icons/chatBubble.svg';
import taskIcon from 'shared-assets/icons/task.svg';
import developmentPlanIcon from 'shared-assets/icons/developmentPlan.svg';
import supportIcon from 'shared-assets/icons/support.svg';
import aboutCompanyIcon from 'shared-assets/icons/about.svg';
import englishFlag from 'shared-assets/icons/english.svg';
import swedishFlag from 'shared-assets/icons/swedish.svg';
import zebrainIcon from 'shared-assets/icons/zebrain.svg';
import insightsIcon from 'shared-assets/icons/insights.svg';
import logoTextIcon from 'shared-assets/icons/primary/logoText.svg';


declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}
const firebaseConfig = {
  apiKey: "AIzaSyBwYtmQG33PnzAFxpplqpBpGye11ttFBDc",
  authDomain: "zebrain.firebaseapp.com",
  projectId: "zebrain",
  storageBucket: "zebrain.appspot.com",
  messagingSenderId: "337432544873",
  appId: "1:337432544873:web:32dabdb379af35ced284f8"
};

const app = initializeApp(firebaseConfig);
const DB = getFirestore(app);

// if not localhost
if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://3436c77ca5096a2502811a7920a68314@o4507441234575360.ingest.de.sentry.io/4507583744049232",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { navbarHeight, setNavbarHeight } = useData();
  const navbarRef = useRef(null);
  const translation = useTranslation();
  const { t, i18n } = translation;

  const {
    login,
    logout
  } = useContext(AuthContext);

  const getLanguage = () => {
    // remove -GB from en-GB
    return i18n.language.split('-')[0];
  }

  const handleLanguageChange = () => {

    const newLang = getLanguage() === 'en' ? 'sv' : 'en';
    console.log("Changing language to:", newLang)
    console.log("Current language:", getLanguage())
    i18n.changeLanguage(newLang).then(() => {
      // Save the selected language to localStorage
      localStorage.setItem('language', newLang);
    });
  };

  const calculateShowMenu = () => {
    // Don't show menu if we show the feedback page to external users (?token=[external_token])
    if (location.pathname.startsWith('/feedback/questionnaire') && location.search.startsWith('?token=')) {
      return false
    }
    return true
  }

  const showMenu = calculateShowMenu();
  const menuItems = [
    {
      id: 1,
      title: t('menu-chat'),
      onClick: () => navigate('/'),
      icon: <img src={chatBubbleIcon} alt="chat" />,
    },
    {
      id: 2,
      title: t('menu-my-tasks'),
      onClick: () => navigate('/tasks'),
      icon: <img src={taskIcon} alt="tasks" />,
    },
    {
      id: 3,
      title: t('menu-development-plan'),
      onClick: () => navigate('/development-plan'),
      icon: <img src={developmentPlanIcon} alt="development plan" />,
    },
    {
      id: 4,
      title: t('menu-feedback'),
      onClick: () => navigate('/feedback'),
      icon: <img src={insightsIcon} alt="feedback" className="w-6"/>,
      divider: true,
    },
    {
      id: 5,
      title: t('menu-support'),
      onClick: () => navigate('/support'),
      icon: <img src={supportIcon} alt="support" />,
    },
    {
      id: 6,
      title: t('menu-about-company'),
      onClick: () => navigate('/about'),
      icon: <img src={aboutCompanyIcon} alt="about company" />,
      divider: true,
    },
    {
      id: 7,
      title: getLanguage() === 'en' ? 'SWE' : 'ENG',
      onClick: handleLanguageChange,
      icon: (
        <img
          src={getLanguage() === 'en' ? swedishFlag : englishFlag}
          alt="language"
        />
      ),
    },
  ];

  useEffect(() => {
    if (navbarRef.current) {
      if (!!navbarRef.current.offsetHeight && navbarRef.current.offsetHeight > 0) {
        setNavbarHeight(navbarRef.current.offsetHeight);
      }

    }
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    const isAuth = isAuthenticated && !isLoading;
    if (!isLoading) {
      // TODO: SHOULD BE DEPENDED ON THE LOCAL USER STATE
      if (isAuth && location.pathname === "/auth") {
        const redirectTo = searchParams.get('redirectTo');
        if (redirectTo) {
          navigate(redirectTo);
        }
      } else if (!isAuth) {

      }
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    function setViewportHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('orientationchange', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('orientationchange', setViewportHeight);
    };
  }, []);

  return (
    <ClientDataContextProvider>
      <div className="font-hoves">
        <div className="flex flex-col h-screen-custom">
          <div ref={navbarRef}>
            <Navbar
              icon={showMenu ? zebrainIcon : logoTextIcon}
              showMenu={showMenu}
              login={login}
              logout={logout}
              t={t}
              i18n={i18n}
              menuItems={menuItems}
            />
          </div>
          <div className="flex-1 flex justify-center min-h-0 overflow-y-auto" id="main_for_scrolling">
            <div className="flex flex-col justify-start align-middle w-full desktop:w-151 mx-4">
              <Breadcrumbs breadcrumbConfig={breadcrumbConfig}/>
              <Outlet/>
            </div>
          </div>
          <div className="h-4">&nbsp;</div>
        </div>
      </div>
    </ClientDataContextProvider>
  );
}

export { App, DB };