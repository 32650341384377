// TextLink.tsx
import React from 'react';

interface Props {
  disabled?: boolean;
  className?: string;
  color?: 'primary' | 'white' | 'whitePrimaryMix';
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  text?: string;
}

const colorClasses: Record<string, Record<'default' | 'hover' | 'disabled', string>> = {
  primary: {
    default: 'text-primary-500',
    hover: 'hover:text-primary-400',
    disabled: 'text-primary-500',
  },
  white: {
    default: 'text-white',
    hover: 'hover:text-neutral-400',
    disabled: 'text-white',
  },
  whitePrimaryMix: {
    default: 'text-white',
    hover: 'hover:text-primary-400',
    disabled: 'text-white',
  },
};

const sizeClasses: Record<'xs' | 'sm' | 'base' | 'lg' | 'xl', string> = {
  xs: 'text-xs font-medium',
  sm: 'text-sm font-medium',
  base: 'text-base font-medium',
  lg: 'text-lg font-medium',
  xl: 'text-xl font-medium',
};

const iconSizeClasses: Record<'xs' | 'sm' | 'base' | 'lg' | 'xl', string> = {
  xs: 'h-4 w-4',
  sm: 'h-4 w-4',
  base: 'h-6 w-6',
  lg: 'h-6 w-6',
  xl: 'h-6 w-6',
};

const TextLink: React.FC<Props> = ({
  disabled = false,
  className = '',
  color = 'primary',
  size = 'base',
  onClick,
  leftIcon,
  rightIcon,
  children,
  style,
  text = '',
}) => {
  // Determine the text color + hover
  const colorClass = `${colorClasses[color].default} ${
    disabled ? colorClasses[color].disabled : colorClasses[color].hover
  }`;
  const sizeClass = sizeClasses[size];
  const iconClass = iconSizeClasses[size];

  const classes = [
    colorClass,
    sizeClass,
    'inline-flex',
    'items-center',
    'gap-x-2',
    'disabled:pointer-events-none',
    'transition-colors',
    'duration-200',
    'w-full',
    'justify-center',
    'group',
    className,
  ].join(' ');

  return (
    <button
      style={style}
      type="button"
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && <span className={iconClass}>{leftIcon}</span>}
      {text && <span>{text}</span>}
      {children && <span>{children}</span>}
      {rightIcon && <span className={iconClass}>{rightIcon}</span>}
    </button>
  );
};

export default TextLink;
