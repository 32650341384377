import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  disabled?: boolean;
  className?: string;
  buttonType?: 'blueBase' | 'outlineWhiteBase' | 'neutralBase' | 'transparentBase' | 'dangerBase';
  size?: 'sm' | 'md' | 'lg';
  isRound?: boolean;
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  /** New prop for React Router navigation */
  to?: string;
  /** If you want to allow opening in new tab from the link, 
   *  pass target="_blank". React Router 6 passes this to the <a> tag. 
   *  If you are using older versions, you may need an <a> fallback for external links.
   */
  target?: string;
  rel?: string;
}

const colorClasses: Record<string, string> = {
  blue:
    'bg-primary-500 hover:bg-primary-400 active:bg-primary-400 disabled:opacity-40 dark:text-neutral-900',
  'outline-white':
    'bg-transparent ring-inset ring-1 text-white ring-neutral-500 hover:bg-neutral-700 disabled:opacity-40',
  neutral:
    'bg-neutral-700 text-white hover:bg-neutral-600 active:bg-neutral-700 disabled:bg-neutral-600 disabled:opacity-40 disabled:text-neutral-900',
  transparent:
    'bg-transparent text-inherit hover:bg-neutral-600 active:bg-transparent disabled:opacity-40',
  red:
    'bg-cherry-500 hover:bg-cherry-400 active:bg-cherry-500 disabled:opacity-40 dark:text-neutral-900',
};

const regularSizeClasses: Record<'sm' | 'md' | 'lg', string> = {
  sm: 'py-2 px-3 text-xs font-medium',
  md: 'py-3 px-4 text-sm font-medium', 
  lg: 'py-3 px-4 text-base font-medium',
};

const roundSizeClasses: Record<'sm' | 'md', string> = {
  sm: 'h-8 w-8', 
  md: 'h-10 w-10', 
};

const iconSizeClasses: Record<'sm' | 'md', string> = {
  sm: 'h-4 w-4 items-center',
  md: 'h-6 w-6 items-center flex justify-center align-middle',
};

const buttonTypeClasses: Record<Props['buttonType'], string> = {
  blueBase: colorClasses.blue,
  outlineWhiteBase: colorClasses['outline-white'],
  neutralBase: colorClasses.neutral,
  transparentBase: colorClasses.transparent,
  dangerBase: colorClasses.red,
};

const CustomButton: React.FC<Props> = ({
  disabled = false,
  className = '',
  buttonType = 'blueBase',
  size = 'md',
  isRound = false,
  onClick,
  leftIcon,
  rightIcon,
  children,
  style,
  to,       // new prop for React Router
  target,   // optional target (e.g. "_blank")
  rel,      // optional rel
}) => {
  // Determine size-related classes
  const sizeClass = isRound ? roundSizeClasses[size as 'sm' | 'md'] : regularSizeClasses[size];

  // Fully rounded for any isRound scenario
  const roundedClass = 'rounded-full';

  // Width for non-round buttons
  const widthClass = isRound ? '' : 'w-full';

  // Determine icon size
  let iconClass = iconSizeClasses['sm'];
  if (isRound) {
    iconClass = iconSizeClasses[size as 'sm' | 'md'];
  }

  // Combine all classes
  const classes = [
    buttonTypeClasses[buttonType],
    sizeClass,
    roundedClass,
    widthClass,
    'inline-flex',
    'items-center',
    'justify-center',
    isRound ? 'gap-0' : 'gap-x-2',
    'disabled:pointer-events-none',
    'transition-colors',
    'duration-200',
    className,
  ].join(' ');

  // If `to` is provided and we're not disabled, render <Link> (from react-router-dom)
  if (to && !disabled) {
    return (
      <Link
        to={to}
        target={target}
        rel={rel}
        onClick={onClick}
        className={classes}
        style={style}
      >
        {isRound ? (
          leftIcon || rightIcon ? (
            <span className={iconClass}>
              {leftIcon || rightIcon}
            </span>
          ) : (
            children
          )
        ) : (
          <>
            {leftIcon && <span className={iconClass}>{leftIcon}</span>}
            <span>{children}</span>
            {rightIcon && <span className={iconClass}>{rightIcon}</span>}
          </>
        )}
      </Link>
    );
  }

  // Otherwise, render a regular <button>
  return (
    <button
      style={style}
      type="button"
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {isRound ? (
        leftIcon || rightIcon ? (
          <span className={iconClass}>
            {leftIcon || rightIcon}
          </span>
        ) : (
          children
        )
      ) : (
        <>
          {leftIcon && <span className={iconClass}>{leftIcon}</span>}
          <span>{children}</span>
          {rightIcon && <span className={iconClass}>{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default CustomButton;
