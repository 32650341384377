import React, { useRef, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { HiOutlineMicrophone } from "react-icons/hi";
import { MdSend } from "react-icons/md";
import { isMobile } from 'react-device-detect';

import sendIcon from 'shared-assets/icons/send.svg';

const inputAtom = atom('');

interface ChatInputProps {
  pendingMessages: Message[];
  setPendingMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setAwaitAI: React.Dispatch<React.SetStateAction<boolean>>;
  handleSendMessage: (text: string) => void;
  onHeightChange?: (height: number) => void; // Added prop for height change callback
}

const ChatInput: React.FC<ChatInputProps> = ({
  pendingMessages,
  setPendingMessages,
  setAwaitAI,
  handleSendMessage,
  onHeightChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [input, setInput] = useAtom(inputAtom);
  const { t } = useTranslation();

  const sendMessage = () => {
    if (input.trim() === '') return; // Prevent sending empty messages
    handleSendMessage(input);
    setInput(''); // Clear the input field
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const handleResize = () => {
      if (containerRef.current && onHeightChange) {
        const height = containerRef.current.offsetHeight;
        onHeightChange(height);
      }
    };

    handleResize(); // Initial height measurement

    const observer = new ResizeObserver(handleResize);
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [containerRef, onHeightChange]);

  return (
    <div
      className="pb-2 pt-3  border-t border-neutral-700"
      ref={containerRef} // Reference to measure height
    >
      <div className="flex items-center">
        <div className="flex grow items-center bg-neutral-900 rounded-md ring-1 ring-neutral-600 focus-within:ring-2 focus-within:ring-primary-500">
          <textarea
            ref={textareaRef}
            placeholder={t('chat-placeholder')}
            className="flex-1 bg-neutral-900 text-white px-4 py-3 rounded-md resize-none overflow-y-auto max-h-40 border-none focus:outline-none focus:ring-0 focus:border-0 focus:shadow-none"
            style={{ whiteSpace: 'normal' }}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                e.preventDefault();
                sendMessage();
              }
            }}
            rows={1}
          />
        </div>
        <div className="ml-2 p-2 cursor-pointer text-white" onClick={sendMessage}>
          <img src={sendIcon} alt="send" />
        </div>
      </div>
    </div>
  );
};

export default ChatInput;

type Message = {
  id: string;
  ts: Date;
  text: string;
  sender: 'user' | 'assistant' | 'system' | 'function';
};
