import React, { useState } from 'react';

interface TabItem {
    title: string;
    content: JSX.Element;
}

interface Props {
    items: TabItem[];
}

const Tabs: React.FC<Props> = ({ items }) => {
    const [activeTab, setActiveTab] = useState(0);

    const tabColorClasses = 'hs-tab-active:border-primary-500 hs-tab-active:text-primary-500 border-transparent focus:text-primary-500 disabled:opacity-50 dark:text-neutral-400 dark:hover:text-primary-400';
    const tabTextClasses = 'font-medium text-base whitespace-nowrap';
    return (
        <>
            {/* Tab headers */}
            <div className="border-b border-gray-200 dark:border-neutral-700">
                <nav className="flex space-x-1" aria-label="Tabs" role="tablist">
                    {items.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`pb-2 px-1 inline-flex items-center border-b focus:outline-none disabled:pointer-events-none ${tabTextClasses} ${tabColorClasses} ${activeTab === index ? 'active' : ''}`}
                            id={`tabs-with-icons-item-${index}`}
                            data-hs-tab={`#tabs-with-icons-${index}`}
                            aria-controls={`tabs-with-icons-${index}`}
                            role="tab"
                            onClick={() => setActiveTab(index)}
                        >
                            {item.title}
                        </button>
                    ))}
                </nav>
            </div>

            <div className="mt-6 flex-grow min-h-0 ">
                {items.map((item, index) => (
                    <div
                    key={index}
                    id={`tabs-with-icons-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tabs-with-icons-item-${index}`}
                    className={`${activeTab === index ? '' : 'hidden'} h-full`}
                    >
                    {item.content}
                    </div>
                ))}
            </div>
        </>
    );
}

export default Tabs;