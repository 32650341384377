import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAuth0 } from '@auth0/auth0-react';

import {CustomButton} from 'shared-components';
import Modal from './components/Modal';
import Api from "../../services/Api";


export default function SupportPage() {
  const api = Api.getInstance()
  const translation = useTranslation();
  const { t } = translation;
  // const { getAccessTokenSilently } = useAuth0();

  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
  }

  const sendSupportTicket = () => {
    if (!description) {
      setErrorMessage(t('support-error-message'));
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    setIsModalOpen(true);

    const data = {
      description: description
    }
    api.post('client/support_ticket', data).then((response) => {
      console.log(response.json)
      setSuccessMessage(t('support-success-message'));
      setDescription('');
      setIsModalOpen(true);
      setIsLoading(false);
    }).catch(error => console.log(error))
  }


  // const handleSendSupportTicket = async () => {
  //   if (!description) {
  //     setErrorMessage(t('support-error-message'));
  //     return;
  //   }
  //
  //   setIsLoading(true);
  //   setErrorMessage('');
  //   setSuccessMessage('');
  //
  //   setIsModalOpen(true);
  //   try {
  //     const token = await getAccessTokenSilently();
  //     const response = await fetch('https://api.dazzle.zebrain.se/support_ticket', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         description,
  //       }),
  //     });
  //
  //     if (response.ok) {
  //       setSuccessMessage(t('support-success-message'));
  //       setDescription('');
  //
  //       setIsModalOpen(true);
  //     } else {
  //       setErrorMessage(t('support-error-message-server'));
  //     }
  //   } catch (error) {
  //     setErrorMessage(t('support-error-message-server'));
  //   }
  //
  //   setIsLoading(false);
  // };

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
        {t("support-nav-title")}
      </h1>
      <div className="gap-2 flex flex-col p-1">
        <div className="p-3 mb-4  h-40 items-center bg-neutral-900 rounded-md ring-1 ring-neutral-600 focus-within:ring-2 focus-within:ring-primary-500">
          <textarea
            id="description"
            placeholder={t('support-description-placeholder')}
            className="w-full h-full flex-1 bg-neutral-900 text-white px-4 py-3 rounded-md resize-none max-h-40 border-none focus:outline-none focus:ring-0 focus:border-0 focus:shadow-none"
            style={{ whiteSpace: 'normal' }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <CustomButton
          onClick={sendSupportTicket}
          className="w-full"
          disabled={!description && isLoading}
        >
          {isLoading ? t('support-sending') : t('send')}
        </CustomButton>
        <Modal isOpen={isModalOpen} onClose={onCloseModal} />
        {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
        <p className="mt-4 dark:text-white">{t('support-email-info')}</p>
        <p className="dark:text-neutral-400">support@zebrain.se</p>
      </div>
    </>
  );
}