import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

const FeedbackModal = ({ isOpen, onClose, onModalStartClick }) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">Feedback</h3>
            </ModalHeader>
            <ModalBody>
                <>
                    <p className="text-normal text-base">{isSwedish ? 'Steg 1:' : 'Step 1:'}</p>
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Granska frågorna som Emma skapar baserat på din uppgift. Be henne att ändra dem om det behövs.'
                            : 'Check the review questions that Emma generates based on your task. If necessary, ask her to modify them.'}
                    </p>
                    <br />
                    <p className="text-normal text-base">{isSwedish ? 'Steg 2:' : 'Step 2:'}</p>
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Välj mottagare.'
                            : 'Select recipients.'}
                    </p>
                    <br />
                    <p className="text-normal text-base">{isSwedish ? 'Steg 3:' : 'Step 3:'}</p>
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Granska och skicka förfrågan.'
                            : 'Check and send the request.'}
                    </p>
                </>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onModalStartClick} >
                    {t('start')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FeedbackModal;