import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import i18n from 'i18next';
import '../../lang/i18n';
import {useTranslation} from 'react-i18next';
import TabMenu from "../../components/TabMenu/TabMenu";
import calendarIcon from 'shared-assets/icons/calendar.svg';
import letterIcon from 'shared-assets/icons/letter.svg';
import profileIcon from 'shared-assets/icons/profile.svg';
import learningIcon from 'shared-assets/icons/learning.svg'
import taskIcon from 'shared-assets/icons/task.svg';
import infoIcon from 'shared-assets/icons/white/information-circle.svg'
import ScoreTable from "../../components/ScoreTable";
import ScoreBadge from "../../components/ScoreBadge";
import {CustomButton, Modal, ModalBody, ModalHeader} from 'shared-components';
import { FeedbackQuestionInterface, FeedbackResultInterface } from "../../interfaces/common";
import {monthYearDate, monthDate} from "../../helpers/dates";
import Api from "../../services/Api";

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();



const FeedbackResultPage = () => {
    const api = Api.getInstance();
    const translation = useTranslation();
    const { t } = translation;
    const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
    const [selectedQuestion, setSelectedQuestion] = useState(0)
    const [selectedResult, setSelectedResult] = useState(0)
    const navigate = useNavigate();
    const { id, feedback_type } = useParams();
    const [noAnswerInfo, setNoAnswerInfo] = useState<boolean>(false);
    const [feedbackResults, setFeedbackResults] = useState<Array<FeedbackResultInterface>>([]);
    const [loaded, setLoaded] = useState(false);

    const agreeOptions = [
        t('strongly-agree'),
        t('agree'),
        t('neutral'),
        t('disagree'),
        t('strongly-disagree'),
        t('not-answered'),
    ];

    const getData = () => {
        api.get('client/feedback-results/' + feedback_type + '/' + id).then((response) => {
            setFeedbackResults(response.json.insights)
            setLoaded(true)
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const createAiQuestion = (question: FeedbackQuestionInterface) => {

        let result = t("help-me-analyze-feedback") + ":"
        result += "\nFocus Area: " + feedbackResults[selectedResult].title
        result += "\nQuestion: " + question.question
        if (feedbackResults[selectedResult].hasSelfAssessment) {
            const pos = 5 - question.selfScore
            result += "\nMy self-assessment answer was: " + agreeOptions[pos]
        }

        result += "\nAnswers of feedback givers: "
        for (let i = 0; i < question.responses.length; i++) {
            const responsePos = question.responses.length - 1 - i
            const answer = question.responses[responsePos]
            result += `\n${agreeOptions[i]} - [${answer}]`
            if (question.selfScore === responsePos) {
                result += " + [me]"
            }
        }

        return result
    }

    const askEmmaToAnalyze = (feedbackQuestion: FeedbackQuestionInterface) => {
        const helpMeAnalyze = createAiQuestion(feedbackQuestion)
        // console.log(helpMeAnalyze)
        navigate(`/?message=${encodeURIComponent(helpMeAnalyze)}`)
    }

    const renderQuestion = () => {
        if (feedbackResults.length < 1) {
            return (<></>)
        }
        const feedbackResult = feedbackResults[selectedResult]
        const feedbackQuestion = feedbackResult.questions[selectedQuestion]

        const responses = [...feedbackQuestion.responses]

        let selfScorePos = -1
        if (feedbackResult.hasSelfAssessment) {
            selfScorePos = feedbackQuestion.responses.length - 1 - feedbackQuestion.selfScore

        }

        return (
          <>
              {/* Description card */}
              <div className="p-4 bg-neutral-700 rounded-md mt-4">
                  <h2 className="text-lg font-medium text-white mb-2">
                      {t("question")} {selectedQuestion + 1}
                  </h2>
                  <p className="text-neutral-400">{feedbackQuestion.question}</p>
                  {
                      (feedbackResult.hasSelfAssessment && feedback_type) &&
                        <ScoreTable question={feedbackQuestion} type={feedback_type}
                                    circleNumbers={false}/>
                  }


                  <h2 className="text-lg font-medium text-white mt-4">
                      {t("answers")}
                  </h2>

                  {/* Answers horizontal bar chart. Reverse the array, since we show highest value first */}
                  <div className="w-full flex flex-col gap-4 mt-4">
                      {responses.reverse().map((responsesNr, idx) => {
                          // Calculate percentage for this answer
                          const percentage = responsesNr > 0 ?
                            (responsesNr / feedbackResult.responses) * 100 : 0;


                          return (
                            <div key={`answer-${idx}`} className="">
                                {/* Label and Value Section */}
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center">
                                        <div className="text-sm font-medium text-neutral-400">
                                            {agreeOptions[idx]}
                                        </div>
                                        {
                                          idx === 5 &&
                                            <div className="ml-2">
                                                <img src={infoIcon} alt="info" className="h-4 cursor-pointer"
                                                     onClick={() => setNoAnswerInfo(true)}/>
                                        </div>
                                        }
                                    </div>

                                    <div className="flex items-center">
                                        {
                                        (idx === selfScorePos && feedback_type) &&
                                          <div className="mr-2">
                                              <ScoreBadge txt={t("you")} type={feedback_type} size="sm" self={true}/>
                                          </div>
                                        }
                                        <img src={profileIcon} alt="profile icon" className="w-4 h-4"/>
                                        <span className="ml-2 text-white font-medium">
                                        {responsesNr}
                                    </span>
                                    </div>
                                </div>
                                {/* Bar Section */}
                                <div className="w-full bg-neutral-600 rounded-full h-5 mt-1">
                                    <div
                                      className={"bg-" + (feedback_type === "task" ? "royal" : "peach") + "-400 h-5 rounded-full"}
                                      style={{width: `${percentage}%`}}>
                                        {
                                          percentage > 0 &&
                                          <div
                                            className="flex justify-end items-center text-black text-xs font-medium pr-1">
                                              {percentage.toFixed(0)}%
                                          </div>
                                        }
                                    </div>
                                </div>
                            </div>
                          );
                      })}
                  </div>

                  <CustomButton className="mt-6" buttonType="outlineWhiteBase"
                                leftIcon={<img src={emmaAvatar} alt="emma"/>}
                                onClick={() => askEmmaToAnalyze(feedbackQuestion)}
                  >
                      {t("discuss-this-result")}
                  </CustomButton>
              </div>
          </>
        )
    }

    if (!loaded) {
        return <div>Loading...</div>
    }

    const isNotReadyReason = (): string|null => {
        let responses = 0
        let feedbackUsers = 0
        let _deadline = ""
        if (feedbackResults.length > 0) {
            const _feedbackResult = feedbackResults[selectedResult]
            responses = _feedbackResult.responses
            feedbackUsers = _feedbackResult.feedbackUsers
            _deadline = _feedbackResult.deadline
        }

        if (feedback_type === "task") {
            if (responses > 0) {
                return null
            } else {
                return lang === 'sv' ? 'Vi visar resultatet när någon har svarat' :
                  'We will show the result when someone has answered'
            }
        } else {
            if (responses < 3) {
                return lang === 'sv' ? 'Det behövs minst 3 svar' : 'We need at least 3 answers'
            } else {
                if (responses === feedbackUsers) {
                    return null
                } else {
                    const deadline = new Date(_deadline)
                    if (deadline < new Date()) {
                        return null
                    }
                    return lang === 'sv' ?
                      'Alla har inte svarat' :
                      'Everybody has not answered'
                }
            }
        }
    }

    const showNotReady = (reason: string|null) => {
        return (
          <div className="mt-6">
              <div className="text-xl">
                  {lang === 'sv' ? 'Väntar på feedback': 'Waiting for feedback'}
              </div>
              <div className="mt-2">
                  {reason ? reason : "Undefined?"}.
              </div>
          </div>
        )
    }

    const showResult = () => {
        return (
            <>
                <div className="mt-6 font-medium text-lg">{t('questions')}</div>

                  <div className="flex flex-wrap mt-2">
                  {
                    feedbackResults[selectedResult].questions.map((question, index) => {
                        let cls = "rounded-full border mb-2 mr-2 border-neutral-600 px-3 py-1 " +
                          "text-sm font-medium cursor-pointer hover:bg-neutral-800"
                        if (index === selectedQuestion) {
                            cls += " bg-neutral-600"
                        }
                        return (
                          <div key={"q_" + index} onClick={() => setSelectedQuestion(index)}
                               className={cls}>
                              {index + 1} (Gap: {Math.abs(question.gap).toFixed(1)})
                          </div>
                        )
                    })
                  }
                  </div>
                {renderQuestion()}
            </>
        )
    }

    const generateTabItems = () => {
        const items: Array<any> = []
        for (let i = 0; i < feedbackResults.length; i++) {
            const d = new Date(feedbackResults[i].created)
            items.push({
                title: monthDate(d, lang),
                callback: () => {
                    setSelectedResult(i);
                    selectedQuestion >= feedbackResults[i].questions.length ?
                      setSelectedQuestion(0) : console.log('no need to change question')
                }
            })
        }
        return items
    }

    const notReadyReason = isNotReadyReason()

    if (feedbackResults.length < 1) {
        return (
          <div className="flex flex-col h-full flex-grow min-h-0 pr-2">
              {
                  showNotReady(notReadyReason)
              }
          </div>
        )
    }
    const feedbackResult = feedbackResults[selectedResult]

    return (
      <div className="flex flex-col h-full flex-grow min-h-0 pr-2">
          <Modal isOpen={noAnswerInfo} onClose={() => setNoAnswerInfo(false)}>
              <ModalHeader onClose={() => setNoAnswerInfo(false)}>
                  <div className="text-lg font-medium">{t("why-no-answer")}</div>
              </ModalHeader>
              <ModalBody>
                  <div className="font-medium">{t("why-no-answer-1")}</div>
                  <div className="mt-1">{t("why-no-answer-2")}</div>

                  <div className="font-medium mt-4">{t("why-no-answer-3")}</div>
                  <div className="mt-1">{t("why-no-answer-4")}</div>

                  <div className="font-medium mt-4">{t("why-no-answer-5")}</div>
                  <div className="mt-1">{t("why-no-answer-6")}</div>
              </ModalBody>
          </Modal>
          <h1 className="text-2xl font-medium text-white">
              {
                  feedbackResult.hasSelfAssessment ? t("feedback-vs-self-assessment") : t("received-feedback")
              }
          </h1>

          {
            feedbackResults.length > 1 &&
            <div className="mt-4">
                <TabMenu items={generateTabItems()}/>
            </div>
          }

          {/* Questionnaire info */}
          <div className="flex flex-col gap-3 mt-4">
              <div className="flex items-center gap-1">
                  <img src={feedback_type === 'focus_area' ? learningIcon : taskIcon} alt="area" className="w-6 h-6"/>
                  <p className="text-sm font-medium text-white">
                      {feedback_type === 'focus_area' ? t("focus-area") : t("task")}: {feedbackResult.title}
                  </p>
              </div>
              <div className="flex items-center gap-1">
                  <img src={calendarIcon} alt="calendar icon" className="w-6 h-6"/>
                  <p className="text-sm font-medium text-white">
                      {t("request-created-at")}: {monthYearDate(new Date(feedbackResult.created), lang)}
                  </p>
              </div>
              <div className="flex items-center gap-1">
                  <img src={letterIcon} alt="letter icon" className="w-6 h-6"/>
                  <p className="text-sm font-medium text-white">
                      {t("received")}: {feedbackResult.responses} {t("out-of")} {feedbackResult.feedbackUsers}
                  </p>
              </div>
              <div className="flex items-center gap-1">
                  <img src={profileIcon} alt="learning icon" className="w-6 h-6"/>
                  <p className="text-sm font-medium text-white leading-tight">
                      {t("requested-from")}: {feedbackResult.feedbackNames.join(", ")}
                  </p>
              </div>
          </div>

          {
              notReadyReason === null ? showResult() : showNotReady(notReadyReason)
          }

      </div>
    );
}

export default FeedbackResultPage;
