// Modal.tsx
import React from 'react';
import { ModalVariantContext, ModalVariant } from '../../contexts/ModalContext';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  variant?: ModalVariant;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, variant = 'neutral' }) => {
  const variantClasses = {
    neutral: {
      background: 'bg-neutral-700',
    },
    warning: {
      background: 'bg-cherry-200',
    },
  };

  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? 'flex' : 'hidden'} items-center justify-center rounded-md`}
      role="dialog"
      aria-modal="true"
    >
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <ModalVariantContext.Provider value={variant}>
        <div
          className={`relative ${variantClasses[variant].background} rounded-md shadow-lg z-50 w-full max-w-lg mx-4 desktop:mx-0`}
        >
          {children}
        </div>
      </ModalVariantContext.Provider>
    </div>
  );
};

export default Modal;
