import React, { useState, useRef, useEffect } from 'react';

interface InputComponentProps {
  value: string;
  onError: (error: string) => void;
  onChange: (value: string, isValid: boolean) => void;
  placeholder: string;
  errorText?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  validators?: Array<(text: string) => { isValid: boolean; error: string }>;
  onSubmit?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  bgClass?: string
}

const InputComponent = ({
  value,
  onChange,
  placeholder,
  leftIcon = null,
  rightIcon = null,
  validators = [],
  onError = () => {},
  onSubmit = (e) => {},
  bgClass,
  ...props
}: InputComponentProps) => {
  const hasLeftIcon = Boolean(leftIcon);
  const hasRightIcon = Boolean(rightIcon);

  // Use standard tailwind classes instead of ps-12/pe-12 unless your config supports them.
  const paddingLeft = hasLeftIcon ? 'pl-12' : 'pl-4';
  const paddingRight = hasRightIcon ? 'pr-12' : 'pr-4';

  const [error, setError] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const bg = bgClass ? bgClass : "bg-neutral-900"
  const inputClasses = `
    py-3 ${paddingLeft} ${paddingRight} block w-full ${bg} text-white rounded-md
    border-none ring-1 ring-neutral-600 focus:outline-none 
    focus:ring-2 focus:ring-primary-500 resize-none overflow-hidden
  `;

  const adjustHeight = () => {
    console.log("---------")
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      // If empty, scrollHeight might be small. Still, this ensures we see at least one line.
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    let isValid = true;
    let errorMessage = '';

    validators.forEach((validator) => {
      const { isValid: valid, error } = validator(newValue);
      if (!valid) {
        isValid = false;
        errorMessage = error;
      }
    });

    setError(errorMessage);
    onChange(newValue, isValid);
    adjustHeight();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  // useEffect(() => {
  //   // Wait a frame to ensure layout is stable
  //   requestAnimationFrame(() => {
  //     adjustHeight();
  //   });
  // }, [value]);

  // useEffect(() => {
  //   adjustHeight();
  // }, []);

  return (
    <div className="px-0.5 min-h-12">
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          className={`${inputClasses} box-border border border-gray-300 focus:ring-2 focus:ring-blue-500`}
          style={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            // Set a minimum height to ensure one line visible even if empty:
            minHeight: '2.5rem', 
            lineHeight: '1.5'
          }}
          rows={1}
        />

        {hasLeftIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
            {leftIcon}
          </div>
        )}

        {hasRightIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-4">
            {rightIcon}
          </div>
        )}
      </div>

      {error && <p className="text-cherry-500">{error}</p>}
    </div>
  );
};

export default InputComponent;
