// ModalHeader.tsx
import React, { useContext } from 'react';
import { ModalVariantContext } from '../../../../contexts/ModalContext';

import closeIcon from 'shared-assets/icons/close.svg';
import { CustomButton } from 'shared-components';

interface ModalHeaderProps {
  children: React.ReactNode;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, onClose }) => {
  const variant = useContext(ModalVariantContext);

  const variantClasses = {
    neutral: {
      border: 'border-neutral-600',
      "text": 'text-white',
    },
    warning: {
      border: 'border-cherry-400',
      text: 'text-neutral-900',
    },
  };

  return (
    <div
      className={`flex justify-between items-center py-3 px-4 border-b ${variantClasses[variant].border} ${variantClasses[variant].text}`}
    >
      {children}
      <CustomButton
        buttonType="transparentBase"
        size="sm"
        isRound={true}
        leftIcon={<img src={closeIcon} alt="Info" className="w-4 h-4" />}
        onClick={(onClose)}
    >
    </CustomButton>

    </div>
  );
};

export default ModalHeader;
