import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils';

// This atom initializes with the token from localStorage or defaults to null if not present
const baseTokenAtom = atom(localStorage.getItem('token') ?? null);

// This atom handles getting and setting the token, ensuring it's also updated in localStorage
export const tokenAtomWithPersistence = atom(
  (get) => get(baseTokenAtom),
  (get, set, newToken) => {
    set(baseTokenAtom, newToken);  // Update the atom's value
    localStorage.setItem('token', newToken);  // Persist the new token to localStorage
  },
);

// Type for each question
interface Question {
  id: number; // Question ID
  description: string; // Question description
  type: string; // Question type
  isLoading: boolean; // Loading state
}

// Type for each recipient
interface Recipient {
  name?: string; // Recipient name
  email: string; // Recipient email
}

// Type for the questionnaire
interface Questionnaire {
  structure: Question[]; // Array of drafts
  recipients: Recipient[]; // Array of recipients
  origin: Origin; // Origin of the questionnaire
  cleanState: boolean; // Rerender state
  hasSent: boolean; // Sent state
  remoteId: string; // Questionnaire remote ID
}

interface Origin {
  id: string;
  type: string;
  content: string;
  title: string;
}


// Define the initial structure of the questionnaire
const initialQuestionnaire: Questionnaire = {
  structure: [],
  recipients: [],
  origin: {
    id: '',
    type: '',
    content: '',
    title: '',
  },
  cleanState: false,
  hasSent: false,
  remoteId: '',
};

// Create a typed atom for the questionnaire with persistence in local storage
export const questionnaireAtom = atomWithStorage<Questionnaire>('questionnaire', initialQuestionnaire);
