import { on } from 'process';
import React from 'react';

interface BadgeProps {
    /** Content to display inside the badge */
    children: React.ReactNode;

    /** Optional callback function when the remove button is clicked */
    onRemove?: () => void;

    /** Optional className for additional styling */
    className?: string;

    /** Optional aria-label for accessibility */
    label?: string;

    /** Optional left icon */
    leftIcon?: string;

    /** Optional right icon */
    rightIcon?: string;

    /** Optional callback function when the left icon is clicked */
    onLeftIconClick?: () => void;

    /** Optional callback function when the right icon is clicked */
    onRightIconClick?: () => void;

    /** Size */
    size?: 'sm' | 'md';

    /** Round */
    round?: boolean;

    /** Color */
    color?: string;
}

const Badge: React.FC<BadgeProps> = ({
    children,
    onRemove,
    className = '',
    label = '',
    leftIcon,
    rightIcon,
    onLeftIconClick,
    onRightIconClick,
    size = 'md',
    round = false,
    color = 'bg-neutral-700',

}) => {

  const sizeClasses = {
    sm: 'py-1 px-2',
    md: 'py-2 px-3',
  };

  const roundClasses = round ? 'rounded-full' : 'rounded-md';


    return (
        <span
          className={`inline-flex items-center gap-x-1 text-sm font-medium leading-none  text-neutral-300 ${color} ${roundClasses} ${sizeClasses[size]} ${className}`}
        >
          {leftIcon && (
            <img
              src={leftIcon}
              className="w-4 h-4 cursor-pointer"
              onClick={onLeftIconClick}
            />
          )}
          <span>{label}</span>
          {rightIcon && (
            <img
              src={rightIcon}
              className="w-4 h-4 cursor-pointer"
              onClick={onRightIconClick}
            />
          )}
        </span>
      );

    return (
        <span
            className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium bg-neutral-700 text-neutral-300 ${className}`}
        >
            {children}
            {onRemove && (
                <button
                    type="button"
                    className="shrink-0 size-4 inline-flex items-center justify-center rounded-full focus:outline-none"
                    onClick={onRemove}
                >
                    <span className="sr-only">{label}</span>
                    <svg
                        className="shrink-0 size-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </button>
            )}
        </span>
    );
};

export default Badge;
