import React from 'react';

interface Props {
  disabled?: boolean;
  className?: string;
  buttonType?: 'blueBase' | 'outlineWhiteBase' | 'neutralBase' | 'transparentBase' | 'dangerBase';
  size?: 'sm' | 'md' | 'lg';
  isRound?: boolean;
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const colorClasses: Record<string, string> = {
  blue:
    'bg-primary-500 hover:bg-primary-400 active:bg-primary-400 disabled:opacity-40 dark:text-neutral-900',
  'outline-white':
    'bg-transparent ring-inset ring-1 text-white ring-neutral-500 hover:bg-neutral-700 disabled:opacity-40',
  neutral:
    'bg-neutral-700 text-white hover:bg-neutral-600 active:bg-neutral-700 disabled:bg-neutral-600 disabled:opacity-40 disabled:text-neutral-900',
  transparent:
    'bg-transparent text-inherit hover:bg-neutral-600 active:bg-transparent disabled:opacity-40',
  red:
    'bg-cherry-500 hover:bg-cherry-400 active:bg-cherry-500 disabled:opacity-40 dark:text-neutral-900',
};

// Size classes for regular (rectangular) buttons
const regularSizeClasses: Record<'sm' | 'md' | 'lg', string> = {
  sm: 'py-2 px-3 text-xs font-medium',
  md: 'py-3 px-4 text-sm font-medium', 
  lg: 'py-3 px-4 text-base font-medium',
};

// Size classes for round buttons
const roundSizeClasses: Record<'sm' | 'md', string> = {
  sm: 'h-8 w-8', 
  md: 'h-10 w-10', 

};

// Icon size classes
const iconSizeClasses: Record<'sm' | 'md', string> = {
  // Round buttons
  sm: 'h-4 w-4 items-center',
  md: 'h-6 w-6 items-center flex justify-center align-middle',
};

const buttonTypeClasses: Record<Props['buttonType'], string> = {
  blueBase: `${colorClasses.blue}`,
  outlineWhiteBase: `${colorClasses['outline-white']}`,
  neutralBase: `${colorClasses.neutral}`,
  transparentBase: `${colorClasses.transparent}`,
  dangerBase: `${colorClasses.red}`,
};

const CustomButton: React.FC<Props> = ({
  disabled = false,
  className = '',
  buttonType = 'blueBase',
  size = 'md',
  isRound = false,
  onClick,
  leftIcon,
  rightIcon,
  children,
  style,
}) => {
  // Determine size-related classes
  const sizeClass = isRound ? roundSizeClasses[size] : regularSizeClasses[size];

  // Determine rounded classes
  const roundedClass = 'rounded-full'; // Fully rounded for both types

  // Determine width for regular buttons
  const widthClass = isRound ? '' : 'w-full';

  // Determine icon size
  let iconClass = iconSizeClasses['sm']
  if (isRound) {
    iconClass = iconSizeClasses[size];
  }


  // Combine all classes
  const classes = [
    buttonTypeClasses[buttonType],
    sizeClass,
    roundedClass,
    widthClass,
    'inline-flex',
    'items-center',
    'justify-center',
    isRound ? 'gap-0' : 'gap-x-2',
    'disabled:pointer-events-none',
    'transition-colors',
    'duration-200',
    className,
  ].join(' ');

  return (
    <button
      style={style}
      type="button"
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {isRound ? (
        // For round buttons, render only the icon
        leftIcon || rightIcon ? (
          <span className={iconClass}>
            {leftIcon || rightIcon}
          </span>
        ) : (
          children
        )
      ) : (
        // For regular buttons, render icons and children
        <>
          {leftIcon && <span className={iconClass}>{leftIcon}</span>}
          <span>{children}</span>
          {rightIcon && <span className={iconClass}>{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default CustomButton;
