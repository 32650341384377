import React from 'react';

import { CustomButton as Button, CustomCard as Card, CardHeader, CardBody, CardFooter } from 'shared-components'
import Markdown from 'react-markdown'
import { useNavigate } from 'react-router-dom';

import '../../../lang/i18n';
import { useTranslation } from 'react-i18next';

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();

interface TaskCardProps {
    taskId?: string;
    title: string;
    description: string;
    deadline?: string;
    onClickChat?: () => void;
    onClickFeedback: () => void;
    leftIcon?: string;
    icon?: string;
    questionnaireId: string|null;
}

const TaskCard = ({
    taskId,
    title,
    description,
    deadline,
    onClickChat,
    onClickFeedback,
    leftIcon,
    icon,
    questionnaireId,
}: TaskCardProps) => {

    const translation = useTranslation();
    const { t } = translation;
    const navigate = useNavigate();
    const cardClasses = `bg-white dark:bg-neutral-800 rounded-md border border-transparent cursor-pointer`;

    return (
        <Card className={cardClasses}>
            <CardHeader className="flex items-center px-4 py-3 dark:bg-neutral-700 rounded-t-md">
                {leftIcon && (
                    <img src={leftIcon} alt="icon" className="w-6 h-6 mr-1.5" />
                )}
                <h3 className="text-base font-medium text-gray-800 dark:text-white flex-grow">
                    {title}
                </h3>
                {icon && <img src={icon} alt="status icon" className="w-6 h-6" />}
            </CardHeader>
            <CardBody className="p-4">
                {deadline && (
                    <p className="text-sm text-gray-500 dark:text-neutral-400 mb-2">
                        {deadline}
                    </p>
                )}
                <Markdown>
                    {description}
                </Markdown>
            </CardBody>
            <CardFooter>
                <div className="flex desktop:flex-row flex-col justify-center gap-2">
                    {taskId && <Button
                        className="w-full"
                        onClick={onClickChat}
                        buttonType='outlineWhiteBase'
                        leftIcon={<img src={emmaAvatar} alt="icon" className="w-4 h-4 mr-1.5" />}
                    >
                        {t('chat')}
                    </Button>}
                    {
                        (questionnaireId && taskId) ? (
                          <Button
                            className="w-full"
                            onClick={() => navigate('/feedback/result/task/' + taskId)}
                            buttonType='outlineWhiteBase'
                          >
                              {t('see-feedback')}
                          </Button>
                        ) : (
                          <Button
                            className="w-full"
                            onClick={onClickFeedback}
                            buttonType='outlineWhiteBase'
                          >
                              {t('request-feedback')}
                          </Button>
                        )
                    }

                </div>
            </CardFooter>
        </Card>
    );
};

export default TaskCard;