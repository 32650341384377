import {DevelopmentAreaInterface, FeedbackProgressInterface} from "../interfaces/common";

export function isProgressDone(progress: FeedbackProgressInterface) {
  if (!progress.has_self_assessment) {
    return false;
  }
  // We need at least three respondents
  if (progress.num_answers < 3) {
    return false;
  }
  // If we have enough answers or deadline is passed, return true
  console.log('num answers ' + progress.num_answers + ' total_users ' + progress.total_feedback_users)
  return progress.num_answers >= progress.total_feedback_users ||
    new Date(progress.deadline) < new Date();

}

export function isAreaDone (area: DevelopmentAreaInterface): boolean {
  if (area.feedback_progresses.length === 0) {
    return false;
  }

  return isProgressDone(area.feedback_progresses[area.feedback_progresses.length - 1])
}

export function isDeadlinePassed(progress: FeedbackProgressInterface|null) {
  if (progress) {
    return new Date(progress.deadline) < new Date();
  }
  return false
}