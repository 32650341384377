import React, {useContext, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AiOutlineLoading3Quarters} from "react-icons/ai";

import '../../lang/i18n';

import {NavigationContext} from '../../contexts/NavigationContext';
import {CustomButton, Modal, ModalBody, ModalHeader} from 'shared-components';
import iconInfo from 'shared-assets/icons/white/information-circle.svg'
import './FocusAreaPage.css'; // Import the CSS file
import QuestionnaireState from "./components/QuestionnaireState";
import ScoreTable from "../../components/ScoreTable";
import {DevelopmentAreaInterface, FeedbackQuestionInterface} from "../../interfaces/common";
import ReadMore from "../../components/ReadMore";
import {isAreaDone} from "../../helpers/questionnaire";
import {scrollTop} from "../../helpers/scrolling";
import {calculateScoreRespondents, calculateScoreSelf} from "../../helpers/feedback";

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();

export default function FocusAreaPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [developmentArea, setDevArea] = useState<DevelopmentAreaInterface|null>(null);
  const [scoreInfo, setScoreInfo] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const [conclusion, setConclusion] = useState("")
  const { setShowNavigation } = useContext(NavigationContext);

  const navigate = useNavigate();
  const { id } = useParams();

  const translation = useTranslation();
  const { t } = translation;

  useEffect(() => {
    scrollTop()
    getOverview().then(() => console.log('Loaded overview'))
  }, [])

  const getOverview = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      'https://feedback-api.dazzle.zebrain.se/focus_area_overview',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const developmentAreas = await response.json() as Array<DevelopmentAreaInterface>;
    for (const developmentArea of developmentAreas) {
      if (developmentArea.id === id) {
        // Uncomment below for mock data
        // developmentArea.feedback_progresses = [
        //   {
        //     "questionnaire_id": "v7NifkNg",
        //     "client_id": "martin",
        //     "created": "2024-12-05T17:05:57.735Z",
        //     "deadline": "2025-01-24T14:24:47.605Z",
        //     "last_feedback": "2025-01-12T14:24:47.605Z",
        //     "has_self_assessment": true,
        //     "num_answers": 3,
        //     "total_feedback_users": 3,
        //     "feedback_results": [
        //       {
        //         "question_index": 0,
        //         "answer_value": 4,
        //         "num_answers": 3
        //       },
        //       {
        //         "question_index": 1,
        //         "answer_value": 2,
        //         "num_answers": 3
        //       },
        //     ],
        //     "self_assessment_answer": [
        //       {
        //         "type": "AgreementScale",
        //         "value": 4
        //       },
        //       {
        //         "type": "AgreementScale",
        //         "value": 3
        //       }
        //     ]
        //   }
        // ]
        setDevArea(developmentArea);
        if (developmentArea.feedback_progresses.length > 0) {
          getConclusion(developmentArea.feedback_progresses[developmentArea.feedback_progresses.length - 1].questionnaire_id)
        }
        // console.log(developmentArea)
      }
    }
    setLoaded(true)
  }

  const getConclusion = async (questionnaireId: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `https://feedback-api.dazzle.zebrain.se/questionnaire/${questionnaireId}/analysis`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      setConclusion("Failed getting conclusion from Emma...")
      return
    }

    const conclusionResponse = await response.json();
    setConclusion(conclusionResponse)
  }

  useEffect(() => {
    setShowNavigation(true);
    return () => setShowNavigation(false);
  }, [setShowNavigation]);

  // display spinner if no data
  if (!loaded) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <AiOutlineLoading3Quarters className="text-6xl animate-spin" />
      </div>
    );
  }
  if (developmentArea === null) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Error: failed loading data
      </div>
    );
  }

  const createQuestion = () => {
    // This should be changed so that we get pre-caculated values from endpoint. Hack for now :-/
    const progress = developmentArea.feedback_progresses[developmentArea.feedback_progresses.length - 1]
    const average = calculateScoreRespondents(progress.feedback_results)
    const selfScore = calculateScoreSelf(progress.self_assessment_answer)
    const gap = selfScore - average
    const question: FeedbackQuestionInterface = {
      average: average,
      selfScore: selfScore,
      gap: gap,
      responses: [],
      question: ""
    }
    return question
  }

  const renderInfoModal = () => {
    return (
      <Modal isOpen={scoreInfo} onClose={() => setScoreInfo(false)}>
        <ModalHeader onClose={() => setScoreInfo(false)}>
          <div className="text-lg font-medium">{t("understanding-your-scores")}</div>
        </ModalHeader>
        <div style={{maxHeight: 'calc(100vh - 90px)', overflowY: 'auto'}}>
          <ModalBody>
            <ScoreTable question={createQuestion()}
                        type="focus_area" size="small"/>

            <div className="mt-4 text-white">{t("self-assessment")}:</div>
            <div className="text-base text-neutral-300">{t("average-of-your-answers")}</div>

            <div className="mt-4 text-white">{t("feedback-score")}:</div>
            <div className="text-base text-neutral-300">
              {t("average-feedback-colleagues")}
            </div>

            <div className="mt-4 text-white">{t("perception-gap")}:</div>
            <div className="text-base text-neutral-300">
              <p>{t("difference-between-above")}.</p>
              <p className="mt-4">
                {t("striped-areas-represents")}
              </p>
            </div>

            <div className="mt-4 text-white">{t("how-we-calculate-score")}:</div>
            <div className="text-base text-neutral-300">
              <p>{t("how-we-calculate-text")}</p>
            </div>

            <div className="mt-4 text-white">{t("answer-score-correlation")}:</div>
            <div className="mt-1 text-base text-neutral-400 flex justify-between">
              <p>{t('strongly-agree')}</p>
              <p>5.0</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>

            <div className="text-base text-neutral-400 flex justify-between">
              <p>{t('agree')}</p>
              <p>4.0</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>

            <div className="text-base text-neutral-400 flex justify-between">
              <p>{t('neutral')}</p>
              <p>3.0</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>

            <div className="text-base text-neutral-400 flex justify-between">
              <p>{t('disagree')}</p>
              <p>2.0</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>

            <div className="text-base text-neutral-400 flex justify-between">
              <p>{t('strongly-disagree')}</p>
              <p>1.0</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>

            <div className="text-base text-neutral-400 flex justify-between">
              <p>{t('not-answered')}</p>
              <p>{t('not-included')}</p>
            </div>
            <hr className="mt-1 border-neutral-600"/>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  return (
    <>
      {
        scoreInfo && renderInfoModal()
      }
      <div
        className="flex flex-col gap-6 pr-4"
        // style={{
        //   marginBottom: `${isMobile ? navbarHeight + 30 : 0}px`,
        // }}
      >
        {/* TITLE */}
        <div>
          <h1 className="text-2xl font-medium dark:text-white">
            {developmentArea.title}
          </h1>
        </div>
        <div>
          <ReadMore linkAbove={false} initialHeight="4rem">
            <div className="markdown prose dark:prose-invert prose-slate whitespace-pre-line">
              {developmentArea.description}
            </div>
          </ReadMore>
        </div>
      </div>


      {/* QUICK SELECT BUTTONS*/}
      <div className="flex flex-col gap-2">
        <div className="mt-6 flex items-center">
          <div className="text-lg font-medium dark:text-white">{t("current-state")}</div>
          {
            isAreaDone(developmentArea) &&
            <img src={iconInfo} alt="info" className="ml-2 cursor-pointer"
                 onClick={() => setScoreInfo(!scoreInfo)} />
          }
        </div>

        <QuestionnaireState developmentArea={developmentArea} type="focus_area" questionnaireId={id} conclusion={conclusion}/>

        <div className="mt-4 flex items-center">
          <h2 className="text-lg font-medium dark:text-white mb-1">
            {t('get-coaching-on-focus-area')}
          </h2>
        </div>

        <CustomButton
          buttonType='outlineWhiteBase'
          leftIcon={<img src={emmaAvatar} alt="icon" className="w-4 h-4 mr-1.5" />}
          onClick={() => navigate(`/?message=${t('focus-area-start-chat')} ${developmentArea?.title}`)}
          className="w-full"
        >
          {t('focus-area-start-chat-button')}
        </ CustomButton>
        <CustomButton
          buttonType='outlineWhiteBase'
          leftIcon={<img src={emmaAvatar} alt="icon" className="w-4 h-4 mr-1.5" />}
          onClick={() => navigate(`/?message=${t('focus-area-learn-more-button')} ${developmentArea?.title}`)}
          className="w-full"
        >
          {t('focus-area-learn-more-button')}
        </ CustomButton>
      </div>
    </>
  );
}
