import React, { useEffect, useState } from 'react';
import {useAuth0} from '@auth0/auth0-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

import alertIcon from 'shared-assets/icons/red/alert.svg';
import badgeCheckIcon from 'shared-assets/icons/green/badge-check.svg';
import calendarXIcon from 'shared-assets/icons/calendar-x.svg';
import iconInfo from 'shared-assets/icons/white/information-circle.svg'

import {CustomButton, RadioButton, Modal, ModalBody, ModalHeader} from 'shared-components';

interface ResponseInterface {
    value: number | null
    type: string
}

const FeedbackRespondentPage = () => {
    const navigate = useNavigate(); // Hook to navigate programmatically
    const { t } = useTranslation(); // i18n translation hook
    const { getAccessTokenSilently } = useAuth0();
    const currentLang = i18n.language || 'en';
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string|null>(null);
    const [data, setData] = useState({});
    const [showFeedbackSent, setShowFeedbackSent] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const { id } = useParams();
    // State to store user responses
    const [responses, setResponses] = useState<Array<ResponseInterface>>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [selfAssessment, setSelfAssessment] = useState(false)
    const [selfInfo, setSelfInfo] = useState(false)

    // Define standard agree/disagree options
    const agreeOptions = [
        t('not-answered'),
        t("strongly-disagree"),
        t("disagree"),
        t("neutral"),
        t("agree"),
        t("strongly-agree"),
    ];

    useEffect(() => {
        if (!id) {
            return;
        }
        const getData = async () => {
            setLoading(true);

            try {
                let url: string
                // https://feedback-api.dazzle.zebrain.se/questionnaire/${id}
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': ''
                }
                if (token) {
                    url = `https://feedback-api.dazzle.zebrain.se/feedback/questionnaire/${id}?token=${token}`
                } else {
                    url = `https://feedback-api.dazzle.zebrain.se/questionnaire/${id}`
                    const loggedInToken = await getAccessTokenSilently();
                    headers['Authorization'] = `Bearer ${loggedInToken}`
                    setSelfAssessment(true)
                }
                const response = await fetch(url, {
                    method: 'GET',
                    headers: headers,
                });
        
                if (!response.ok) {
                    console.log('Handling non-OK response...');
                    
                    // Optionally, log the response body for debugging
                    const errorText = await response.text();
                    console.log('Error Response Body:', errorText);
        
                    if (response.status === 404) {
                        console.log('Status 404: Invite code invalid or expired.');
                        setError('Invite code is invalid or has expired. Please check your code and try again.');
                        return; // Exit early
                    }
        
                    if (response.status === 409) {
                        console.log('Status 409: Already submitted.');
                        setError('already-submitted');
                        return; // Exit early
                    }
        
                    // Handle other unexpected status codes
                    const generalError = `Unexpected error: ${response.status} ${response.statusText}`;
                    console.error(generalError);
                    setError(generalError);
                    return; // Exit early
                }
        
                console.log('Processing successful response...');
                let data;
                try {
                    data = await response.json();
                    console.log('Response JSON:', data);
                } catch (jsonError) {
                    console.error('Failed to parse JSON:', jsonError);
                    setError('Invalid response format.');
                    return;
                }
        
                if (data.deadline) {
                    data.deadline = new Date(data.deadline);

                    if (data.deadline < new Date()) {
                        console.log('Deadline has passed.');
                        setError('deadline-passed');
                    } else {
                        console.log('Setting data state.');
                        setData(data);
                        console.log('Questionnaire Data:', data);
                    }
                } else {
                    console.log('No deadline found in data.');
                    setError('deadline-passed');
                }
            } catch (err) {
                console.error('An error occurred in getData:', err);

                // Determine the type of error and set appropriate messages
                if (err instanceof TypeError) {
                    // Likely a network error or CORS issue
                    setError('Network error. Please check your connection and try again.');
                } else {
                    // Other types of errors
                    setError('Failed to fetch questionnaire data.');
                }
        
            } finally {
                console.log('Setting loading to false.');
                setLoading(false);
            }
        };
        
        getData();
    }, [token, id]);

    useEffect(() => {
        console.log('Responses:', responses);
    }, [responses]);

    // Handler for radio button changes
    const handleOptionChange = (questionIndex, optionIndex) => {
        setResponses(prevResponses => {
            const newResponses = [...prevResponses];
            newResponses[questionIndex] = {
                type: 'AgreementScale',
                value: optionIndex
            };
            return newResponses;
        });

        // Check if all questions have been answered
        const allAnswered = data.structure.every((question, index) => responses[index]);
        if (allAnswered) {
            setShowAlert(false);
        }
    };

    // Handler for form submission
    const handleSubmit = async () => {
        console.log('Submitting feedback:', JSON.stringify(responses));

        // Check if all questions have been answered
        const allAnswered = data.structure.every((question, index) => responses[index]);
        if (!allAnswered) {
            console.log('Not all questions have been answered.');
            setShowAlert(true);
            return;
        }

        // Do some magic value 0 is Can't answer, the rest should be -1 since we added one option
        const normalizedResponses: Array<ResponseInterface> = []

        for (const response of responses) {
            const value = response.value === 0 ? null
                : response.value === null ? null
                  : response.value - 1
            normalizedResponses.push({
                type: response.type,
                value: value,
            })
        }

        try {
            console.log('Initiating POST request to submit feedback...');

            let url: string
            // https://feedback-api.dazzle.zebrain.se/questionnaire/${id}
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': ''
            }
            if (token) {
                url = `https://feedback-api.dazzle.zebrain.se/feedback/questionnaire/${id}/answers?token=${token}`
            } else {
                url = `https://feedback-api.dazzle.zebrain.se/questionnaire/${id}/self_assessment`
                const loggedInToken = await getAccessTokenSilently();
                headers['Authorization'] = `Bearer ${loggedInToken}`
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(normalizedResponses),
            });

            console.log('POST request completed.');
            console.log('Response Status:', response.status);
            console.log('Response OK:', response.ok);

            if (!response.ok) {
                console.log('Handling non-OK response...');

                // Attempt to parse the error response
                let errorData;
                try {
                    errorData = await response.json();
                    console.log('Error Response JSON:', errorData);
                } catch (parseError) {
                    console.error('Failed to parse error response JSON:', parseError);
                    errorData = null;
                }

                if (response.status === 409) {
                    console.log('Status 409: Feedback already submitted.');
                    setError('already-submitted'); // Specific error for 409
                    return; // Exit early without throwing
                }

                if (response.status === 400) {
                    // Example: Bad Request
                    console.log('Status 400: Bad request.');
                    setError('bad-request');
                    return;
                }

                // Handle other unexpected status codes
                const generalError = `Unexpected error: ${response.status} ${response.statusText}`;
                console.error(generalError);
                setError(generalError);
                return; // Exit early
            }

            // If response is OK, proceed to set feedback as sent
            console.log('Feedback submitted successfully.');
            setShowFeedbackSent(true);

        } catch (error) {
            console.error('Failed to submit user data:', error);

            // Determine the type of error and set appropriate messages
            if (error instanceof TypeError) {
                // Likely a network error or CORS issue
                setError('Network error. Please check your connection and try again.');
            } else {
                // Other types of errors
                setError('Failed to submit feedback.');
            }
        } finally {
            console.log('Submission process completed. Updating loading state.');
            setLoading(false); // Ensure you have a loading state if applicable
        }
    };

    const renderSelfInfo = () => {
        return (
          <Modal isOpen={selfInfo} onClose={() => setSelfInfo(false)}>
              <ModalHeader onClose={() => setSelfInfo(false)}>
                  <div className="text-lg font-medium">{t("self-assessment")}</div>
              </ModalHeader>
              <ModalBody>
                  <div className="text-neutral-400">
                      {t("self-assessment-txt1")}
                  </div>

                  <div className="text-neutral-400 mt-4">
                      {t("self-assessment-txt2")}
                  </div>

                  <div className="text-neutral-400 mt-4">
                      {t("self-assessment-txt3")}
                  </div>

                  <div className="text-neutral-400 mt-4">
                      {t("self-assessment-txt4")}
                  </div>
              </ModalBody>
          </Modal>
        )
    }
    if (error) {
        return (
          <div className="mt-10">
              <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">
                  {error === "deadline-passed" &&
                    <img src={calendarXIcon} alt="Calendar X icon" className="w-12 h-12 mx-auto"/>}
                  <div className="flex flex-col gap-2">
                        <h1 className="text-2xl font-medium dark:text-white text-center">
                            {t(error)}
                        </h1>
                        <p className="text-neutral-400 text-center">
                            {t(error + '-message')} {error === "deadline-passed" && data.client_name}.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (showFeedbackSent) {
        return (
            <div className="mt-10">
                <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">
                    <img src={badgeCheckIcon} alt="Badge check icon" className="w-12 h-12 mx-auto" />
                    <div className="flex flex-col gap-2">
                        <h1 className="text-2xl font-medium dark:text-white text-center">
                            {
                                selfAssessment ? (
                                  <>{t("self-assessment-sent")}</>
                                ) : (
                                  <>{t('feedback-sent')}</>
                                )
                            }
                        </h1>
                        {
                            (selfAssessment) ? (
                              <>
                                  <p className="text-neutral-400 text-center">
                                      {t("self-assessment-submission-ok")}
                                  </p>
                                  {
                                      data.origin.type === "focus_area" ? (
                                        <div
                                          className="mt-6 text-center text-sm font-medium text-white hover:underline cursor-pointer"
                                          onClick={() => navigate(`/development-plan/focus-area/${data.origin.id}`)}>
                                            {t("back-to", { page: t("focus-area-nav-title")})}
                                        </div>
                                      ) : (
                                        <div
                                          className="mt-6 text-center text-sm font-medium text-white hover:underline cursor-pointer"
                                          onClick={() => navigate('/tasks')}>
                                            {t("back-to-tasks")}
                                        </div>
                                      )

                                  }

                              </>

                            ) : (
                              <p className="text-neutral-400 text-center">
                                  {t('thank-you-for-your-feedback')}
                              </p>
                            )
                        }

                    </div>
                </div>
            </div>
        );
    }
    
    if (loading || Object.keys(data).length === 0) {
        return null;
    }

    return (
      <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2">
          {selfInfo && renderSelfInfo()}
          <h1 className="text-2xl font-medium dark:text-white mt-6">
              {
                  selfAssessment ? (
                    <div className="flex items-center">
                        <div>{t("complete-self-assessment")}</div>
                        <img src={iconInfo} alt="info" className="ml-2 h-6 cursor-pointer"
                             onClick={() => setSelfInfo(true)}/>
                    </div>
                  ) : (
                    <>{data.client_name} {t('asking-for-feedback')}</>
                  )
              }
          </h1>

          {
            !selfAssessment &&
            <>
                <div className="text-neutral-400">
                    <ul className="list-disc list-inside">
                        {/*TODO: answered_count contains self-assessment answer as well? */}
                        <li>{data.answered_count}/{data.feedback_users.length} {t("feedback-number-of-feedbacks")}</li>
                        <li>{t("feedback-time-left")} {data.deadline.toLocaleDateString(currentLang, {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        })}</li>
                        {data.feedback_users.length > 2 && <li>{t("feedback-is-anonymous")}</li>}
                    </ul>
                </div>
                <hr className="border-neutral-600"/>
            </>
          }

          <div className="">
              {data.structure.map((question, index) => (
                <div key={"question_" + index} className="mb-6">
                    <h2 className="text-lg font-medium text-white">
                        {t("question")} {index + 1}
                        {/*{`Question ${index + 1}`}*/}
                    </h2>
                    <p className="text-neutral-400">
                        {question.description}
                    </p>
                    {/* Answer options */}
                    <div className="mt-4">
                        <div className="flex flex-col gap-y-2 ">
                            {agreeOptions.toReversed().map((option, idx) => {
                                const reverIdx = agreeOptions.length - 1 - idx;
                                // console.log('----reverIdx:', reverIdx);
                                // console.log('----option:', option);
                                if (selfAssessment && idx === 5) {
                                    return <></>
                                }
                                return (
                                  <RadioButton
                                    key={"alt_" + index + "_" + reverIdx}
                                    id={`question-${index}-option-${reverIdx}`}
                                    name={`question-${index}`}
                                    value={String(idx)}
                                    checked={responses[index]?.value === reverIdx}
                                    onChange={() => handleOptionChange(index, reverIdx)}
                                    label={option}
                                  />
                                )
                            })}
                        </div>
                    </div>
                    <hr className="border-neutral-600 mt-4"/>
                </div>
              ))}
          </div>

          {showAlert && (
            <div
              className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
                <img src={alertIcon} alt="Alert" className="w-6 h-6"/>
                <span>{t('feedback-response-form-alert')}</span>
            </div>
          )}
          <CustomButton onClick={handleSubmit}>
              {t('submit')}
          </CustomButton>
      </div>
    )
};

export default FeedbackRespondentPage;
