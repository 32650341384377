import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import Markdown from 'react-markdown'

import { CustomButton, TextLink } from 'shared-components';

import { questionnaireAtom } from '../../../../jotai_state';
import { useData } from '../../../../contexts/dataContext';
import QuestionCard from '../QuestionCard';
import ShowQuestionsInfoModal from '../ShowQuestionsInfoModal';
import LeaveModal from '../LeaveModal';
import DeleteQuestionModal from '../DeleteQuestionModal';
import MaskedIcon  from '../../../../components/MaskedIcon';
import plusIcon from 'shared-assets/icons/plus.svg';
import alertIcon from 'shared-assets/icons/red/alert.svg';
import infoIcon from 'shared-assets/icons/white/information-circle.svg';
import ReadMore from "../../../../components/ReadMore";

interface CreateQuestionsProps {
    goToNextStep: () => void;
}

const CreateQuestions: React.FC<CreateQuestionsProps> = ({ goToNextStep }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { client_id } = useData();
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const feedbackSourceId = queryParams.get('id');
    const feedbackSourceContent = queryParams.get('content');
    const feedbackSourceTitle = queryParams.get('title');
    const feedbackType = queryParams.get('type');
    const [hasRemovedQuestion, setHasRemovedQuestion] = useState(false);
    const pageName = feedbackType === "task" ? t('menu-my-tasks') : t('focus-area');

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);
    const [isFirstRender, setIsFirstRender] = useState(true);

    // Track text input for regenerating questions
    const [inputValueMap, setInputValueMap] = useState<Record<number, string>>({});

    // Flag that we’ll use to show a global loading spinner for the entire question list
    const [isLoading, setIsLoading] = useState(false);

    const [showNumQuestionsAlert, setShowNumQuestionsAlert] = useState(false);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [questionIdToDelete, setQuestionIdToDelete] = useState<number | null>(null);

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const answerOptions = [
        t('no-answer'),
        t('strongly-disagree'),
        t('disagree'),
        t('neutral'),
        t('agree'),
        t('strongly-agree'),
    ];

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            const hasParams = !!feedbackType && !!feedbackSourceId;
            const isEmpty = questionnaire.structure.length === 0;
            const isSameOrigin = (
                feedbackType === questionnaire.origin.type &&
                feedbackSourceContent === questionnaire.origin.content
            );
            const shouldCleanState = questionnaire.cleanState;
            const differentIds = (questionnaire.id !== feedbackSourceId);

            if (isEmpty && hasRemovedQuestion) {
                // When last question is removed, just generate one new question
                generateOneNewQuestion();
            } else if (hasParams && (isEmpty || !isSameOrigin || shouldCleanState || differentIds)) {
                const origin = {
                    id: feedbackSourceId,
                    type: feedbackType,
                    content: feedbackSourceContent,
                    title: feedbackSourceTitle,
                };
                generateInitialQuestionnaire(origin);
            } else if (isEmpty && !!questionnaire.origin.type) {
                // If the questionnaire is empty, generate new questions
                regenerateQuestions(questionnaire.origin, questionnaire.recipients);
            } else if ((!feedbackType || !feedbackSourceId) && questionnaire.structure.length === 0) {
                backToPreviousPage();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, questionnaire.structure, location]);

    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, [questionnaire.structure]);

    const createNewQuestionBattery = async (origin) => {
        let token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/generate_questionnaire',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        client_id: client_id,
                        origin: origin,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error('Failed to create questionnaire');
            }

            const questionData = await response.json();
            return questionData;
        } catch (error) {
            console.error('Failed to create questionnaire', error);
            throw error;
        }
    };

    const generateInitialQuestionnaire = async (origin) => {
        try {
            // Indicate we are about to fetch
            setIsLoading(true);

            // Fetch entire question list
            let newQuestionsList = await createNewQuestionBattery(origin);

            // Map the result to give them internal IDs
            newQuestionsList = newQuestionsList.map((question, index) => ({
                ...question,
                id: index,
                isLoading: false, // per-question loading
            }));

            setTimeout(() => {
                setQuestionnaire({
                    recipients: [],
                    structure: newQuestionsList,
                    origin: origin,
                    id: feedbackSourceId,
                    cleanState: false,
                });
                setIsLoading(false);
            }, 500);
        } catch (error) {
            alert("Something went wrong. Please try again.");
            backToPreviousPage();
        }
    };

    const regenerateQuestions = async (origin, recipients) => {
        try {
            setIsLoading(true);

            // Fetch entire new set
            const newQuestionsList = await createNewQuestionBattery(origin);

            setTimeout(() => {
                setQuestionnaire((prev) => ({
                    ...prev,
                    origin: origin,
                    structure: newQuestionsList.map((question, index) => ({
                        ...question,
                        isLoading: false,
                        id: index,
                    })),
                    recipients: recipients || [],
                    cleanState: false,
                }));
                setIsLoading(false);
            }, 500);
        } catch (error) {
            alert("Something went wrong. Please try again.");
            backToPreviousPage();
        }
    };

    const fetchOneNewQuestion = async () => {
        let token = await getAccessTokenSilently();
        try {
            const structure = questionnaire.structure.map((q) => ({
                type: 'AgreementScale',
                description: q.description,
            }));
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/add_question',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        origin: {
                            id: feedbackSourceId,
                            type: feedbackType,
                            content: feedbackSourceContent,
                            title: feedbackSourceTitle,
                        },
                        structure,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch new question');
            }

            const questionData = await response.json();
            questionData.id = questionnaire.structure.length;
            questionData.isLoading = false;

            const newStructure = [...questionnaire.structure, questionData];

            setQuestionnaire((prev) => ({
                ...prev,
                structure: newStructure,
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    };

    const generateOneNewQuestion = () => {

        const placeholderQuestion = {
            id: -1,
            isLoading: true,
            description: '',
            type: '',
        };

        setQuestionnaire((prev) => ({
            ...prev,
            structure: [...prev.structure, placeholderQuestion],
        }));
        fetchOneNewQuestion();
    };

    const updateQuestion = async (id: number) => {
        const promptValue = inputValueMap[id];
        const question = questionnaire.structure.find((q) => q.id === id);

        // Mark the question as loading
        setQuestionnaire((prev) => ({
            ...prev,
            structure: prev.structure.map((q) =>
                q.id === id ? { ...q, isLoading: true } : q
            ),
        }));

        const structure = questionnaire.structure.map((q) => ({
            type: 'AgreementScale',
            description: q.description,
        }));

        const origin = {
            id: feedbackSourceId,
            type: feedbackType,
            content: feedbackSourceContent,
            title: feedbackSourceTitle,
        };

        let token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                'https://feedback-api.dazzle.zebrain.se/questionnaire/regenerate_question',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        origin: origin,
                        structure: structure,
                        question_to_regenerate: question.description,
                        prompt: promptValue,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to regenerate question');
            }

            const questionData = await response.json();

            const updatedQuestion = {
                ...question,
                description: questionData.description,
                isLoading: false,
            };

            // Replace old question with updated one
            setQuestionnaire((prev) => ({
                ...prev,
                structure: prev.structure.map((q) =>
                    q.id === id ? updatedQuestion : q
                ),
            }));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    };

    const removeQuestion = () => {
        const id = questionIdToDelete;
        setQuestionnaire({
            ...questionnaire,
            structure: questionnaire.structure.filter((q) => q.id !== id),
        });
        setQuestionIdToDelete(null);

        // This is used to avoid generating new question set after deleting last question
        setHasRemovedQuestion(true);
        setShowDeleteModal(false);
    };

    const LoadingAnimation = () => {
        return (
            <div className="flex items-center justify-center my-6">
                <div className="relative">
                    <div className="w-24 h-24 rounded-full flex items-center justify-center bg-neutral-700">
                        <div className="flex space-x-2">
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0s]" />
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.2s]" />
                            <div className="w-[8px] h-[8px] bg-primary-500 rounded-full animate-dot [animation-delay:0.4s]" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const checkRules = () => {
        const numQuestions = questionnaire.structure.length;
        return numQuestions >= 1;
    };

    const handleNext = () => {
        setShowNumQuestionsAlert(false);
        if(!checkRules()) {
            setShowNumQuestionsAlert(true);
        } else if (questionnaire.structure.length > 0) {
            const anyQuestionLoading = questionnaire.structure.some((q) => q.isLoading);
            if (!anyQuestionLoading) {
                goToNextStep();
            } else {
                console.log('Questions are still loading');
            }
        } else {
            console.log('No questions have been created');
        }
    };


    const backToPreviousPage = () => {
        navigate(feedbackType === "task" ? '/tasks' : '/development-plan/focus-area/' + feedbackSourceId);
    };

    if (isLoading) {
        return (
            <div className="flex flex-col gap-4 bg-neutral-800 rounded-md p-4 min-h-[200px]">
                <div className="flex justify-center">
                    <LoadingAnimation />
                </div>
            </div>
        );
    }

    return (
      <div className="flex flex-col h-full flex-grow min-h-0">
          <div className="flex flex-row items-center gap-1">
              <h1 className="text-xl font-medium dark:text-white">
                  {t('feedback-create-review-questions')}
              </h1>
              <CustomButton
                buttonType="transparentBase"
                onClick={() => setShowInfoModal(true)}
                size="md"
                isRound={true}
                leftIcon={<img src={infoIcon} alt="Info" className="w-6 h-6"/>}
              >
              </CustomButton>
          </div>

          <div className="font-medium mt-6">{feedbackSourceTitle}</div>
          <ReadMore linkAbove={false} initialHeight="82px">
              <div className="mt-2 text-neutral-400">
                  <Markdown>
                      {feedbackSourceContent}
                  </Markdown>
              </div>
          </ReadMore>

          {/* Question cards list */}
          <div ref={scrollableDivRef} className="flex flex-col gap-2 mt-6">
              {questionnaire.structure.map((question, index) => {
                  if (question.isLoading) {
                      // If an individual question is regenerating
                      return (
                          <div
                              key={`loading-${question.id}`}
                              className="flex flex-col bg-neutral-800 rounded-md p-4"
                          >
                              <LoadingAnimation />
                          </div>
                      );
                  }
                  return (
                    <div key={`create-question-card-${question.id}`}>
                        <QuestionCard
                          question={question}
                          removeQuestion={() => {
                              setQuestionIdToDelete(question.id)
                              setShowDeleteModal(true);
                          }}
                          answerOptions={answerOptions}
                          updateQuestion={updateQuestion}
                          setInputValueMap={setInputValueMap}
                          index={index}
                          t={t}
                        />
                    </div>
                  );
              })}
          </div>

          {/* Buttons */}
          <div className="flex flex-col">
              {showNumQuestionsAlert && (
                <div
                  className="flex items-center rounded-md border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2  mt-2">
                    <img src={alertIcon} alt="Alert" className="w-6 h-6"/>
                    <span>{t('create-questions-alert')}</span>
                </div>
              )}
              <div className="mb-6 mt-2">
                  {/* Add Question Button */}
                  <TextLink
                    color="white"
                    onClick={generateOneNewQuestion}
                    className="w-full"
                    leftIcon={<MaskedIcon
                      src={plusIcon}
                      className="h-6 w-6"
                    />}
                    size="base"
                  >
                      {t('feedback-add-question')}
                  </TextLink>
              </div>

              <div className="flex flex-col gap-4">
                  {/* Next Button */}
                  <CustomButton
                    onClick={() => handleNext()}
                    className="w-full"
                  >
                      {t('next')}
                  </CustomButton>


                  {/* Back Link */}
                  <TextLink
                    color="white"
                    onClick={() => setShowLeaveModal(true)}
                    className="w-full"
                    size="sm"
                  >
                      {t('back-to', {page: pageName})}
                  </TextLink>
              </div>
          </div>

          {/* Info Modal */}
          <ShowQuestionsInfoModal
            isOpen={showInfoModal}
            feedbackType={feedbackType ? feedbackType : "task"}
            onClose={() => setShowInfoModal(false)}
          />

          {/* Leave Page Modal */}
          <LeaveModal
            isOpen={showLeaveModal}
            onClose={() => setShowLeaveModal(false)}
            onLeavePage={() => backToPreviousPage()}
          />

          {/* Delete Confirmation Modal */}
          <DeleteQuestionModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onDelete={removeQuestion}
            questions={questionnaire.structure.length}
          />
      </div>
    );
};

export default CreateQuestions;
