import React from 'react';

interface Props {
  score?: number
  txt?: string
  self?: boolean
  type: string
  size?: string
}

const ScoreBadge: React.FC<Props> = (props: Props) => {
  let baseClass = "font-medium flex items-center"
  if (props.size && props.size === "small") {
    baseClass += " text-xs h-4 px-2 rounded-lg"
  } else {
    baseClass += " text-sm h-5 px-4 rounded-xl"
  }
  if (props.type === "task") {
    if (props.self) {
      baseClass += " bg-royal-500 text-neutral-900"
    } else {
      baseClass += " bg-royal-400 text-neutral-900"
    }
  } else if (props.type === "focus_area") {
    if (props.self) {
      baseClass += " bg-peach-500 text-neutral-900"
    } else {
      baseClass += " bg-peach-400 text-neutral-900"
    }
  }
  return (
    <div className={baseClass}>
      {
        props.txt && props.txt
      }
      {props.score !== undefined && props.score.toFixed(1)}
    </div>
  )
}

export default ScoreBadge;