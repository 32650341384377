const monthNamesShortEn = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

const monthNamesShortSv = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec"
]

export function addDays(d: Date, days: number) {
  const newDate = new Date(d);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function monthDate(d: Date, lang: string) {
  const month = lang === 'sv' ? monthNamesShortSv[d.getMonth()] : monthNamesShortEn[d.getMonth()];
  if (lang === "sv") {
    return month + " " + d.getDate()
  } else {
    const day = d.getDate();
    return month + " " + day + (day === 1 ? "st": "th");
  }
}

export function monthYearDate(d: Date, lang: string) {
  const month = lang === 'sv' ? monthNamesShortSv[d.getMonth()] : monthNamesShortEn[d.getMonth()];
  // TODO: check english date format for day
  return d.getDate() + " " + month + " " + d.getFullYear()
}