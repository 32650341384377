// CreateFeedbackPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { CustomButton } from 'shared-components';
import Stepper, { Step } from 'shared-components/src/components/Stepper';
import CreateQuestions from './components/CreateQuestions';
import AddRecipients from './components/AddRecipients';
import Summary from './components/Summary';
import { questionnaireAtom } from '../../jotai_state';

import successIcon from 'shared-assets/icons/green/badge-check.svg';

const CreateFeedbackPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const feedbackType = queryParams.get('type');
    const questionnaireId = queryParams.get('id');
    const pageName = feedbackType === "task" ? t('menu-my-tasks') : t('focus-area');

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const [questionnaireHasLoaded, setQuestionnaireHasLoaded] = useState(false);

    console.log(questionnaire)

    const goToNextStep = () => {
        setCompletedSteps((prev) => {
            const newCompletedSteps = [...prev];
            if (!newCompletedSteps.includes(currentIndex)) {
                newCompletedSteps.push(currentIndex);
            }
            return newCompletedSteps;
        });

        if (currentIndex < stepsData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            // Optionally update completedSteps if needed
        }
    };

    const goToStep = (index: number) => {
        setCurrentIndex(index);
    }

    useEffect(() => {
        /** Reset hasSent to false upon re-entering the creation page to hide the success screen. */

        if (questionnaire.hasSent && questionnaireHasLoaded) {
            setQuestionnaire((prev) => ({
                ...prev,
                hasSent: false,
            }));
        }
    }, [questionnaireHasLoaded]);

    useEffect(() => {
        // This is here just to make sure the questionnaire has loaded before we use it in the useEffect above.
        if(!questionnaireHasLoaded){
            setQuestionnaireHasLoaded(true);
        }
    }, [questionnaire]);

    const stepsData: Step[] = [
        {
            title: t('questions'),
            content: <CreateQuestions goToNextStep={goToNextStep} />,
        },
        {
            title: t('recipients'),
            content: <AddRecipients goToNextStep={goToNextStep} goToPreviousStep={goToPreviousStep} />,
        },
        {
            title: t('summary'),
            content: <Summary goToPreviousStep={goToPreviousStep} goToStep={goToStep}/>,
        },
    ];

    if (questionnaire.hasSent && questionnaire.cleanState && questionnaire.remoteId) {

        return (
            <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2 mt-6">
                {/**  ICON */}
                <div className="flex justify-center mt-10">
                    <img src={successIcon} alt="Success" className="w-12 h-12" />
                </div>
                {/**  TEXT */}
                <div className="flex flex-col gap-2">
                    <h1 className="text-2xl font-medium text-white text-center">
                        {t('feedback-request-sent')}
                    </h1>
                    <p className="text-base font-normal text-white text-center">
                        {
                            feedbackType === 'focus_area' ? t('feedback-create-success-text') :
                              t('feedback-create-success-text-task')
                        }
                    </p>
                </div>
                {/**  BUTTONS */}
                <div className="flex flex-col gap-4">
                    {/* Next Button */}
                    <CustomButton
                        onClick={() => navigate(`/feedback/questionnaire/${questionnaire.remoteId}`) }
                    >
                        {t('start-self-assessment')}
                    </CustomButton>


                    {/* Add Question Button */}
                    <CustomButton
                        buttonType="transparentBase"
                        onClick={() => navigate(feedbackType === "task" ?
                          '/tasks' :
                          '/development-plan/focus-area/' + queryParams.get('id'))}
                    >
                        {t('back-to', { page: pageName })}
                    </CustomButton>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">
            <Stepper
                steps={stepsData}
                currentIndex={currentIndex}
                completedSteps={completedSteps}
                direction="horizontal"
            />
        </div>
    );
};

export default CreateFeedbackPage;
