import React, { useEffect, useRef, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import { useAuth0 } from '@auth0/auth0-react';

import { useAutoScroll } from '../../helpers/autoScroll';
import { useData } from '../../contexts/dataContext';
import ChatInput from './components/ChatInput';
import { CustomButton, TimeAgo } from 'shared-components'

import './ChatView.css';

import profileIcon from 'shared-assets/icons/profile.svg';
import arrowDownIcon from 'shared-assets/icons/white/arrow-narrow-down.svg';

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();

const inputAtom = atom('');
const isAtBottomAtom = atom<boolean>(false);
const showScrollButtonAtom = atom<boolean>(false);

const ChatView: React.FC = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isWelcome, setIsWelcome] = useState(false);
  const [autoSendMessage, setAutoSendMessage] = useState('');
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [filteredChatMessages, setFilteredChatMessages] = useState<Message[]>([]);

  const [input, setInput] = useAtom(inputAtom);
  const [isAtBottom, setIsAtBottom] = useAtom(isAtBottomAtom);
  const [showScrollButton, setShowScrollButton] = useAtom(showScrollButtonAtom);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const { messages, pendingMessages, setPendingMessages, awaitAI, setAwaitAI, glance } = useData();

  const { scrollToBottom, isScrolledOneContainerHeightFromBottom } = useAutoScroll({
    messages: filteredChatMessages,
    containerRef: chatContainerRef,
    setIsAtBottom,
    setShowScrollButton
  });

  const [chatInputHeight, setChatInputHeight] = useState(0); // State to store ChatInput height

  const mergeMessages = (messages: Message[], pendingMessages: Message[]): Message[] => {
    const allMessages = [...messages, ...pendingMessages];
    const uniqueMessagesMap = new Map<string, Message>();

    allMessages.forEach((message) => {
      uniqueMessagesMap.set(message.id, message);
    });

    const mergedMessages = Array.from(uniqueMessagesMap.values());

    return mergedMessages;
  };

  useEffect(() => {
    try {
      const signup = searchParams.get('signup');
      setIsWelcome(signup === 'true');
    } catch (error) {
      setIsWelcome(false);
    }
    try {
      const paramMessage = searchParams.get('message');
      const decodedMessage = paramMessage ? decodeURIComponent(paramMessage) : '';
      setAutoSendMessage(decodedMessage);
    } catch (error) {
      setAutoSendMessage('');
    }
  }, [searchParams]);

  useEffect(() => {
    const mergedMessages = mergeMessages(messages, pendingMessages);
    setChatMessages(mergedMessages);
  }, [messages, pendingMessages, awaitAI]);

  useEffect(() => {
    if (autoSendMessage !== '') {
      handleSendMessage(autoSendMessage);
      searchParams.delete('message');
      setSearchParams(searchParams);
    }
  }, [autoSendMessage]);

  useEffect(() => {
    if (chatMessages.length > 0) {
      setFilteredChatMessages(
        chatMessages.filter(
          (message) => message.sender !== 'system' && message.sender !== 'function'
        )
      );
      scrollToBottom();
    }
  }, [chatMessages]);

  useEffect(() => {
    if (filteredChatMessages.length > 0) {
      scrollToBottom();
    }
  }, [filteredChatMessages]);

  const handleSendMessage = async (text: string) => {
    if (!text) {
      return;
    }
    setInput('');

    const response = await fetch('https://api.dazzle.zebrain.se/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessTokenSilently()),
      },
      body: JSON.stringify({
        message: text,
      }),
    });

    setAwaitAI(true);
    const responseJson = await response.json();
    const id = responseJson.id;
    setPendingMessages([
      ...pendingMessages,
      {
        id: id,
        ts: new Date(),
        text,
        sender: 'user',
      },
    ]);
  };

  // Handle clients who created account without going via the invitation mail. They will have user but empty clients
  if (glance && glance.user_client_info && glance.user_client_info.user
    && glance.user_client_info.clients.length === 0) {
    return (
      <div className="flex justify-center h-full w-full bg-neutral-900 mt-[125px]">
        <div className="flex flex-col justify-start align-middle items-center gap-4 h-full w-full desktop:w-151 px-4">
          <div>
            <img
              src={emmaAvatar}
              alt="avatar"
              className="w-[150px] h-[150px] rounded-full"
            />
          </div>
          <p className="mt-6 text-lg font-medium dark:text-white text-center desktop:px-6">
            Oops!
          </p>
          <p className="text-center">
            It seems you created an account with us without using the invitation mail.
          </p>
          <p className="text-center">
            Please contact <a href="mailto:support@zebrain.se">support@zebrain.se</a> and we will help you.
          </p>
        </div>
      </div>
    )
  }
  if (
    !glance ||
    !glance.user_client_info ||
    !glance.user_client_info.user ||
    glance.user_client_info.clients.length === 0
  ) {
    return null;
  }

  if (isWelcome) {
    return (
      <div className="flex justify-center h-full w-full bg-neutral-900 mt-[125px]">
        <div className="flex flex-col justify-start align-middle items-center gap-10 h-full w-full desktop:w-151 px-4">
          <div>
            <img
              src={emmaAvatar}
              alt="avatar"
              className="w-[150px] h-[150px] rounded-full"
            />
          </div>
          <p className="text-base font-medium dark:text-white text-center desktop:px-6">
            {t('chat-welcome')}
          </p>
          <CustomButton
            buttonType="blueBase"
            extraStyle="mt-4 w-full"
            onClick={() => {
              setIsWelcome(false);
              searchParams.delete('signup');
              setSearchParams(searchParams);
            }}
          >
            {t('continue')}
          </CustomButton>
        </div>
      </div>
    );
  }
    console.log("showScrollButton", showScrollButton)

  return (
    <div className="flex flex-col h-full">
      {/* Chat Messages Container */}
      <div className="flex-grow overflow-y-auto pr-4" ref={chatContainerRef}>
        {filteredChatMessages &&
          filteredChatMessages.length !== 0 &&
          filteredChatMessages.map((message, index) => (
            <div
              id={`${index}-${filteredChatMessages.length}`}
              key={message.id}
              className={`${
                index < filteredChatMessages.length - 1
                  ? 'border-b border-neutral-700'
                  : ''
              }`}
            >
              <div className="flex flex-row">
                <div className="py-6 flex items-start justify-center">
                  <div className="flex w-10 h-10 flex-shrink-0 items-start">
                    <img
                      src={
                        message.sender === 'assistant' ? emmaAvatar : profileIcon
                      }
                      alt="avatar"
                      className="w-8 h-8 rounded-full fill-white"
                    />
                  </div>
                  <div>
                    <div className="pb-1">
                      <span className="font-medium">
                        {message.sender === 'assistant'
                          ? 'AI Emma'
                          : glance.user_client_info.clients[0].client_details.name}
                      </span>{' '}
                      <span className="font-medium text-neutral-400">
                        <TimeAgo date={message.ts} />
                      </span>
                    </div>
                    {message.id !== 'awaiting-response' ? (
                      <div className="prose dark:prose-invert prose-slate">
                        <Markdown>{message.text}</Markdown>
                      </div>
                    ) : (
                      message.text
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* Scroll to Bottom Button */}
      {!showScrollButton && (
        <CustomButton
          onClick={scrollToBottom}
          isRound
          buttonType="neutralBase"
          size="md"
          className="fixed left-1/2 transform -translate-x-1/2 z-20 shadow-lg"
          style={{ bottom: chatInputHeight + 24 + 12 }} // Dynamic positioning
          aria-label="Scroll to Bottom"
        >
          <img src={arrowDownIcon} alt="Scroll to Bottom" />
        </CustomButton>
      )}

      {/* Chat Input */}
      <ChatInput
        pendingMessages={pendingMessages}
        setPendingMessages={setPendingMessages}
        setAwaitAI={setAwaitAI}
        handleSendMessage={handleSendMessage}
        onHeightChange={setChatInputHeight} // Receive ChatInput height updates
      />
    </div>
  );
};

export default ChatView;

type Message = {
  id: string;
  ts: Date;
  text: string;
  sender: 'user' | 'assistant' | 'system' | 'function';
};
