import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, CustomButton as Button} from 'shared-components';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface DeleteQuestionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    questions: number;
}

const DeleteQuestionModal = ({ isOpen, onClose, onDelete, questions }: DeleteQuestionModalProps) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';

    let text: string

    if (questions === 1) {
      text = isSwedish ?
        `Det behövs minst en feedbackfråga. En ny fråga kommer genereras automatiskt.` :
        `The feedback request needs to contain at least one question. A new one will automatically be generated.`;
    } else {
      text = isSwedish ?
        `Om du ångrar dig kan du generera nya frågor.` :
        `If you change your mind, you can generate new questions.`;
    }


    const deleteQuestion = () => {
        onDelete();
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('delete-question')}</h3>
            </ModalHeader>
            <ModalBody>
                <p>{text}</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} buttonType="outlineWhiteBase">{t('cancel')}</Button>
                <Button onClick={deleteQuestion} buttonType="dangerBase">{t('delete')}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteQuestionModal;