import React, { useState } from 'react';

interface TabItem {
  title: string;
  iconUrl?: string;
  callback: Function;
}

interface Props {
  items: TabItem[];
  active?: number;
}

const TabMenu: React.FC<Props> = ({ items, active }) => {
  const [activeTab, setActiveTab] = useState(active ? active : 0);

  const inactiveClasses = 'text-neutral-400 whitespace-nowrap cursor-pointer hover:border-b hover:border-b-primary-400 hover:text-primary-400';
  // const activeClasses = 'underline underline-offset-8 text-primary-500'
  const activeClasses = 'border-b border-b-primary-500 text-primary-500'

  const click = (item: TabItem, index: number) => {
    setActiveTab(index);
    item.callback()
  }

  return (
    <>
      {/* Tab headers */}
      <div className="flex border-b pb-2 border-b-neutral-700">
          {items.map((item, index) => (
            <div key={"tab_" + index}
              className={"mr-4 h-6 flex items-center pb-2 " + (activeTab === index ? activeClasses : inactiveClasses)}
              onClick={() => click(item, index)}
              style={{marginBottom: "-8px"}}
            >
              {
                item.iconUrl &&
                  <img src={item.iconUrl} className="h-4 mr-2" alt="icon" />
              }
              <div className="font-medium">{item.title}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default TabMenu;