import React from 'react';

interface Props {
  score?: number;
  txt?: string;
  self?: boolean;
  type: string;
  size?: string;
}

const ScoreBadge: React.FC<Props> = (props: Props) => {
  let baseClass = "font-medium flex items-center justify-center";

  // Decide between 'small' vs 'default' styles
  if (props.size === "small") {
    // This sets a minimum width of ~3rem (48px), add px-2 for spacing
    baseClass += " text-xs h-4 min-w-[3rem] px-2 rounded-lg";
  } else {
    // This sets a minimum width of ~4rem (64px), add px-2 for spacing
    baseClass += " text-sm h-5 min-w-[4rem] px-2 rounded-xl";
  }

  // Decide color variant based on `type` and `self`
  if (props.type === "task") {
    if (props.self) {
      baseClass += " bg-royal-500 text-neutral-900";
    } else {
      baseClass += " bg-royal-400 text-neutral-900";
    }
  } else if (props.type === "focus_area") {
    if (props.self) {
      baseClass += " bg-peach-500 text-neutral-900";
    } else {
      baseClass += " bg-peach-400 text-neutral-900";
    }
  }

  return (
    <div className={baseClass}>
      {props.txt && props.txt}
      {props.score !== undefined && props.score.toFixed(1)}
    </div>
  );
};

export default ScoreBadge;
